import React from 'react';
import NewsTemplate from '../components/common/NewsTemplate';

export default function Pwc(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Arc-net and PwC join forces to use blockchain in combating food fraud"
      image={require('../img/blog/food-fraud-sq.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        Arc-net and PwC Netherlands sign collaboration bringing blockchain as breakthrough
        technology for traceability.
      </p>
      <p>
        Arc-net, a company that has developed the arc-net platform for supply chain authentication
        and security, has today announced an exciting partnership with PwC Netherlands which will
        see both companies combine their specialist industry knowledge to create a new model for
        food integrity, supply chain security and compliance.
      </p>
      <p>
        In their own report, PwC highlighted that food fraud costs the global economy in excess of
        $40 billion a year. This equates to an additional $5.00 per week on the price of an average
        weekly shop which, in a rising food price environment, is unacceptable. While research from
        the World Health Organisation shows that almost 1 in 10 people worldwide get ill from eating
        contaminated food every year.
      </p>
      <p>
        Arc-net have developed a cloud based platform that utilises the power of blockchain to
        enable food producers and retailers to assure customers of the provenance and authenticity
        of their products.
      </p>
      <p>
        Arc-net and PwC will leverage the power of the arc-net blockchain platform, which is a host
        for product data, and provides access to a toolset that unlocks strategic product and supply
        chain insights.
      </p>
      <p>
        Kieran Kelly, CEO of arc-net, says that this collaboration will provide global food brand
        owners with the ability to deliver on product and brand security whilst delivering supply
        chain mapping and compliance.”
      </p>
      <p>
        He continues “PwC have a rich track record in providing leading edge expertise and insights
        to manage change, navigate complexity and unlock value for their customers. We are extremely
        confident that this partnership will allow both the arc-net platform and PwC to deliver on
        our joint commitment to enhance the security and integrity of the food we eat.”
      </p>
      <p>
        Hans Schoolderman, partner sustainability and food integrity says:“ Where many start-ups and
        others discuss and research the power of new technologies, the arc-net platform offers a
        proven solution based on the power of blockchain that gives ultimate transparency on safety,
        quality and integrity of food. The partnership allows us to bring ARC’s breakthrough
        solution to our clients helping them solve the important challenge of bringing high quality,
        safe and transparent products to the market and improve the trust we have in the food we
        eat.”
      </p>
      <p>
        <b>About Advanced Research Cryptography Ltd</b>
      </p>
      <p>
        At arc-net our aim is to establish the global standard in product and supply chain
        authentication and security. We have built the arc-net platform, which is a cloud based data
        analytics and supply-chain security platform for the food industry. The traceability
        solution connects all elements and organisations within the dedicated supply chain to
        provide the retailer and consumer with 100% traceability, provenance and authenticity
        offering both brand and product protection. Arc-net operates from offices in Belfast and
        Edinburgh, and works with leading companies in food, beverages, aviation, energy,
        manufacturing, luxury goods and national infrastructure.
      </p>
      <p>
        <b>About PwC</b>
      </p>
      <p>
        At PwC, our purpose is to build trust in society and solve important problems. We’re a
        network of firms in 157 countries with more than 223,000 people who are committed to
        delivering quality in assurance, advisory and tax services. Find out more and tell us what
        matters to you by visiting us at www.pwc.com.
      </p>
      <p>
        PwC refers to the PwC network and/or one or more of its member firms, each of which is a
        separate legal entity. Please see www.pwc.com/structure for further details.
      </p>

      <p>
        1. PWC
        <a href="http://www.pwc.com/foodtrust/" target="_blank" rel="noopener noreferrer">
          http://www.pwc.com/foodtrust/
        </a>
      </p>
      <p>
        2.
        <a
          href="http://www.who.int/mediacentre/news/releases/2015/foodborne-disease-estimates/en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          World Health Organisation
        </a>
      </p>
    </NewsTemplate>
  );
}
