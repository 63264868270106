import React from 'react';
import newsItems, { featured } from '../../News';

export default function NewsRoute(props) {
  let result;
  let NewsComponent;
  const { match } = props;

  if (featured.path === match.params.postID) {
    result = featured;
    NewsComponent = featured.component;
  } else {
    result = newsItems.find(post => post.path === props.match.params.postID);
    NewsComponent = result.component;
  }
  return (
    <div className="news-route">
      <NewsComponent
        locations={
          {
            next: result.next,
            previous: result.previous,
          }}
      />
    </div>
  );
}
