import React from 'react';
import NewsTemplate from '../components/common/NewsTemplate';

export default function traceability(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Arc-net's traceability innovations will take center stage at the Global Food Fraud Conference in April 2017"
      image={require('../img/blog/traceability-sq.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        Arc-net are honoured to be appearing at the Food Fraud – Global Understanding Conference
        taking place in Quebec City, Canada on April 5th 2017.
      </p>
      <p>
        Food Fraud – Global Understanding is one of the major food safety gatherings of 2017. With a
        focus on global prevention and mitigation of food fraud, the conference represents an
        invaluable opportunity to review current knowledge, mobilise resources and create momentum
        for collective action.
      </p>
      <p>
        Our founder Kieran Kelly will be taking his place amongst some of the most respected figures
        working towards securing our food supply. The conference brings together a number of leading
        experts from renowned organisations such as Queens University, Belfast, Food Safety
        Authority of Ireland (FSAI), Food and Agriculture Organisation of the United Nations (FAO)
        and the China National Centre for Food Safety Centre, China (CFSA).
      </p>
      <p>
        Kieran will be discussing Ultimate Traceability, focusing on it’s challenges, requirements
        and arc-net’s journey towards implementation in the food sector.
      </p>
      <p>
        We can’t wait to share the work we have been doing in the food safety sector and look
        forward to what we can learn from the work of others in this important field.
      </p>
      <p>
        For more information on Food Fraud – Global Understanding 2017 and the preliminary program,
        visit:
        <a
          href="http://foodfraudconference.ca/en/global-understanding-of-food-fraud/global-understanding-of-food-fraud/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.foodfraudconference.ca/en/global-understanding-of-food-fraud/global-understanding-of-food-fraud/
        </a>
      </p>
    </NewsTemplate>
  );
}
