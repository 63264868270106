import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LinkContainer } from 'react-router-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './NavigationMenu.css';
import { connect } from 'react-redux';
import { toggleClick, setActiveTab, setLoading } from '../../store/actions';
import harvestID from '../../img/products/harvest-id/harvest-ID.svg';
import trustflight from '../../img/products/trustflight/trustflight.svg';
import distilledID from '../../img/products/distilled-id/distilled-ID.svg';
import logo from '../../img/arcnet-logo-blue.svg';
import spacer from '../../img/icons/spacer-icon.svg';

class NavigationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
    this.closeMenu = this.closeMenu.bind(this);
  }

  componentDidMount() {
    this.fireProgressBarOnLoad();
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  fireProgressBarOnLoad() {
    const { setLoad } = this.props;
    setTimeout(() => {
      setLoad(true);
      setTimeout(() => {
        setLoad(false);
      }, 1200);
    }, 100);
  }

  render() {
    const { togglePress, setTab, loading } = this.props;
    const { menuOpen, hover, show } = this.state;
    return (
      <div className="navigation-menu">
        <div
          role="button"
          tabIndex={0}
          onKeyDown={this.handleKeyDown}
          onClick={() => this.setState({ menuOpen: false })}
          className="overlay"
          style={menuOpen ? { display: 'initial' } : { display: 'none' }}
        />
        <Navbar
          style={
            window.innerWidth > 1340
              ? {
                paddingLeft: (window.innerWidth - 1340) / 2,
                paddingRight: (window.innerWidth - 1340) / 2,
                margin: 'auto',
              }
              : null
          }
          bg="gradient"
          expand="lg"
          variant="dark"
          expanded={menuOpen}
          onSelect={this.closeMenu}
          onToggle={() => this.setState({ menuOpen: !menuOpen })}
          collapseOnSelect
        >
          <Navbar.Brand>
            <LinkContainer to="/">
              <Nav.Link active={false}>
                <img src={logo} alt="arc-net logo" />
              </Nav.Link>
            </LinkContainer>
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={() => this.setState({ menuOpen: !menuOpen })}
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <div className="menu-container">
                <div className="outer outer-left">
                  <LinkContainer to="/platform">
                    <Nav.Link active={false} className="center-menu-item">
                      Platform
                    </Nav.Link>
                  </LinkContainer>
                </div>
                <div className="middle">
                  <NavDropdown
                    onClick={() => {
                      this.setState({ hover: !hover });
                    }}
                    onMouseEnter={() => {
                      this.setState({ hover: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ hover: false });
                    }}
                    show={hover}
                    onToggle={() => this.setState({ show: !show })}
                    title="Products"
                    id="basic-nav-dropdown"
                  >
                    <Row className="dropdown-row">
                      <Col
                        className="dropdown-column column-one"
                        lg={{ span: 2, offset: 2 }}
                        sm={12}
                      >
                        <h1>Our products</h1>
                      </Col>
                      <Col
                        className="dropdown-column column-two"
                        lg={2}
                        sm={12}
                      >
                        <img src={spacer} alt="spacer" className="spacer" />
                        <LinkContainer
                          onClick={() => {
                            togglePress(true);
                            this.closeMenu();
                            setTab('#harvestid');
                          }}
                          to={{ pathname: '/' }}
                        >
                          <div className="dropdown-column-content arrow-link">
                            <div id="dropdown-food">
                              <h1>Food and farming</h1>
                              <img
                                src={harvestID}
                                className="image"
                                alt="harvestID logo"
                              />
                            </div>
                          </div>
                        </LinkContainer>
                      </Col>
                      <Col
                        className="dropdown-column column-two"
                        lg={2}
                        sm={12}
                      >
                        <LinkContainer
                          onClick={() => {
                            togglePress(true);
                            this.closeMenu();
                            setTab('#distilledid');
                          }}
                          to={{ pathname: '/' }}
                        >
                          <div className="dropdown-column-content arrow-link">
                            <div id="dropdown-spirits">
                              <h1>Spirits and Breweries</h1>
                              <img
                                src={distilledID}
                                className="image"
                                alt="distilledID logo"
                              />
                            </div>
                          </div>
                        </LinkContainer>
                      </Col>
                    </Row>
                  </NavDropdown>
                </div>
                <div className="outer outer-right">
                  <LinkContainer to="/about">
                    <Nav.Link active={false}>About</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/news">
                    <Nav.Link active={false}>News</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/contact">
                    <Nav.Link active={false}>Contact</Nav.Link>
                  </LinkContainer>
                </div>
                <div className="right">
                  <LinkContainer to="/demo">
                    <Nav.Link active={false} className="request-a-demo">
                      Request a demo
                    </Nav.Link>
                  </LinkContainer>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className={`loading-bar ${loading ? 'animate-bar' : ''}`} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clicked: state.clicked,
  activeTab: state.activeTab,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  togglePress: clickState => dispatch(toggleClick(clickState)),
  setTab: activeTab => dispatch(setActiveTab(activeTab)),
  setLoad: loadingState => dispatch(setLoading(loadingState)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu);
