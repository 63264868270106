import React from 'react';
import { Link } from 'react-router-dom';
import NewsTemplate from '../components/common/NewsTemplate';

export default function EuChina(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Arc-net selected for multi-million pound EU-China food safety project"
      image={require('../img/blog/eu-china-sq.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        Arc-net announced as technology partner for one of the world’s largest food safety
        projects, utilising blockchain.
      </p>
      <p>
        The Innovative EU - China Collaboration programme aims to revolutionise the global approach
        to tackling food fraud.
      </p>
      <p>
        Arc-net, a Belfast-headquartered supply chain traceability company, has been selected to act
        as technology partner to one of the world’s largest food safety projects.
      </p>
      <p>
        The multi-million-pound European Horizon 2020 programme will be led by Queen’s University
        Belfast’s Institute for Global Food Security(IGFS) and the Chinese Ministry of Science and
        Technology(MOST).
      </p>
      <p>
        The EU-China-Safe project will involve key players in the food industry, research
        organisations and Governments across two of the world’s largest trading areas.
      </p>
      <p>Arc-net will provide its technology platform to help enable the project to happen.</p>
      <p>
        EU-China-Safe aims to reduce food fraud and improve food safety through focusing on
        traceability, authenticity as well as improving food legislation, food inspection and
        increasing access to information across both continents supply chain networks State of the
        art technologies, including arc-net’s blockchain powered platform and virtual laboratories
        will create a unique space to share and demonstrate best practice.
      </p>
      <p>
        Kieran Kelly, CEO or arc-net said: “arc-net are delighted to be selected as the official
        technology partner in such a ground-breaking and transformative project. Arc-net’s mission
        has always been to ensure the health of current and future generations by providing access
        to safe and authentic food for current and future generations and we see this project as a
        vital step in achieving this goal. The use of innovative technologies will result in the
        creation of fully transparent supply chain network which will become the foundation for a
        trusted digital community.”
      </p>
      <p>
        Professor Elliott, the project’s co-ordinator said: “We are delighted that The Institute for
        Global Food Security(IGFS) at Queen’s University Belfast will lead this global project.
        Working together with arc-net and key stakeholders in the global food system will help to
        address the importance of food traceability and security across two of the world’s largest
        trading markets.”
      </p>
      <p>
        Professor Yongning Wu, Chief Scientist from the China National Centre for Food Safety Risk
        Assessment, co-ordinator of the Chinese efforts in the project stated: “The EU-China Safe
        partnership between our two trading regions is of immense important to help deliver safe and
        genuine food to all citizens.
      </p>
      <p>
        “Working together across the EU and China will enable us to identify where food fraud is
        happening, address the root causes and thereby enable us to improve food safety standards
        for all our citizens.”
      </p>
      <p>
        Food fraud manifests itself in many ways, from horse meat labelled and sold as beef, as was
        the case in Europe in 2013, to illicit oil which saw slaughterhouse waste and sewage used in
        cooking oil, known as the ‘gutter oil’ scandal in China in 2014.
      </p>
      <p>
        Reported instances of food fraud are on the increase and occur on a global scale, worth an
        estimated $52 billion globally each year. Food fraud is a global issue demanding a global
        response. The increasingly complex global food supply network increases the risks of serious
        food borne illness.
      </p>
      <p>
        <b>Blockchain</b>
      </p>
      <p>
        Blockchain is a cryptographically sealed distributed database that is designed to maintain a
        continuously growing list of records called blocks. Each block contains a timestamp and a
        secure link to the previous block, creating a ‘Chain-of-Custody’. The arc-net blockchain is
        managed by its authorised community members collectively adhering to a protocol for
        validation and authentication of products and services.
      </p>
      <p>
        Blockchain was explicitly designed to function in an environment where participants need to
        establish a trust model.Records held on the arc-net blockchain database are immutable and
        immune to third party, non-authorised access.
      </p>
      <p>
        With more counterfeited and adulterated goods entering the market, consumers have an even
        greater need to find trust in suppliers. The arc-net blockchain provides consumers with
        access to authentic information from creation to consumption and helps consumers make
        informed choices through transparency.
      </p>
      <p>
        <b>About arc-net</b>
      </p>
      <p>
        Arc-net is a cloud based product authentication and traceability solution which connects all
        elements of organisations supply chain to provide 100 % traceability through the power of
        the blockchain. The arc-net blockchain technology is a core component to form a sequence of
        events into an immutable chain of custody and bring trust to an untrusted world.
      </p>
      <p>
        The arc-net platform provides producers, processors, retailers and consumers with the
        ability to validate and authenticate produce from creation to consumption. Arc-net was
        founded by Northern Ireland entrepreneur Kieran Kelly in 2014, and has offices in Edinburgh
        in addition to its Belfast base.
      </p>
      <p>
        <b>Notes to Editor:</b>
      </p>
      <p>
        1. The partnership is made up of 33 partners including 15 in the EU and 18 in China. EU
        Partners: Queen’s University Belfast, Ysoka Skola Chemicko - Cechnologicka V Praze,
        Bundesinstitut Fuer Risikobewertung, Agriculture And Food Development Authority, University
        College Dublin, Wageningen University, Fundacion Azti - Azti Fundazioa, Nofima As, Advanced
        Research Cryptography Limited, Jochen Kleboth, Euroquality Sarl, Fera Science Limited,
        Cranswick Country Foods Plc, Nestec SA.
      </p>
      <p>
        China Partners: China National Center For Food Safety Risk Assessment, Chinese Academy Of
        Inspection & Quarantine, Beijing Center For Disease Prevention And Control, Iangsu Entry
        Exit Inspection And Quarantine Bureau, Shanghai Entry - Exit Inspection Andquarantine Bureau
        Of The People Of Republica Of China Seal, China Meat Research Center, China National
        Research Institute Of Food And Fermentation Industries Soe, China Agricultural University,
        Tianjin University Of Science And Technology, Zhejiang Academy Of Agricultural Sciences,
        Yangtze Delta Region Institute Of Tsinghua University Zhejiang, Ningbo Academy Of Inspection
        And Quarantine Comprehensive Service Center For Technical Trade Measures, United States
        Pharmacopeia Standard R & D And Technical Service(Shanghai) Co Ltd, Danone Asia - Pacific
        Management Co Ltd, Nestle R & D China Ltd, Inner Mongolia Yili Industrial Group Co.Ltd,
        Jiangsu Yurun Meat Foods Co Ltd, Hong Kong Polytechnic University(来自我的华为手机)
      </p>

      <p>
        <b>
          Want to know more about protecting your business from risk?
          <Link to="/contact"> Get in touch with arc-net today</Link>
        </b>
      </p>
    </NewsTemplate>
  );
}
