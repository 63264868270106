import React from 'react';
import NewsTemplate from '../components/common/NewsTemplate';

export default function blockChainFoodIndustry(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="How the blockchain can revolutionise the food industry"
      image={require('../img/blog/blockchainfood1.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        When it comes to our food supply, what we don’t know most certainly can hurt us.
        <a
          href="http://www.who.int/mediacentre/news/releases/2015/foodborne-disease-estimates/en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          The World Health Organisation estimates that almost 1 in 10 people become ill every year
          from eating contaminated food, with 420,000 dying as a result.
        </a>
        &nbsp;Our global food supply has grown so complex that it has become almost impossible for
        food producers and retailers to guarantee, with any kind of certainty, the provenance
        of their products.
      </p>
      <p>
        As with any industry, where there is opportunity there will inevitably be those who
        take advantage. Individuals tampering with our food is nothing new, in the
        <a
          href="http://www.newfoodmagazine.com/wp-content/uploads/NSF-Whitepaper.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;17th Century fraudsters would water down milk and add chalk to bread in order to
          squeeze out as much profit as possible.
        </a>
      </p>
      <p>
        While technology may have improved here in the 21st Century,
        <a href="https://www.bloomberg.com/news/articles/2016-02-16/the-parmesan-cheese-you-sprinkle-on-your-penne-could-be-wood" target="_blank" rel="noopener noreferrer">
          &nbsp;similar methods used in our food today
        </a>
        give the phrase “you are what you eat” an unsettling connotation. Many are aware of
        high profile incidents of food fraud, from the
        <a
          href="https://www.theguardian.com/uk-news/2016/aug/26/three-men-charged-over-uk-horsemeat-scandal"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;2013 UK Horsemeat scandal
        </a>
        &nbsp;to the

        <a
          href="http://www.foodsafetynews.com/2012/04/2009-peanut-butter-outbreak-three-years-on-still-no-resolution-for-some/#.WOtvgFPytTZ"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;2009 Salmonella peanut butter outbreak.
        </a>
        &nbsp;
      </p>
      <p>
        Of course the size of such events naturally bring huge national attention to
        the security of our food supply, but what must be remembered is that these are
        not outliers. Our food supply is under constant threat from adulteration, with
        food fraud estimated to cost UK families up to
        <a
          href="https://www.netnames.com/assets/shared/whitepaper/pdf/The-Online-Counterfeit-Economy-web-FINAL.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          £1.17 billion a year.
        </a>
      </p>
      <p>
        For organisations who find themselves caught up in a food scandal, the
        consequences can be dire. According to the GMA, a single food fraud
        incident can cost up to
        <a
          href="https://www.pwc.com/gx/en/food-supply-integrity-services/publications/food-fraud.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;15% of a company’s annual revenue,
        </a>
        &nbsp;not to mention the damage to organisational reputation and brand
        loyalty that inevitably occurs.
      </p>
      <p>
        As we have seen with the the 2008 Financial Crisis, and its subsequent revelations which
        significantly damaged public faith in the banking sector, there is an opportunity for
        technology to fill such gaps in trust.
      </p>
      <p>
        It is becoming all too apparent that current food safety and quality management
        systems are simply not designed to detect, nor sophisticated enough to prevent,
        fraud occurring in our food.
      </p>
      <p>
        So what can be done to bring trust back into our food? Well, according to
        <a
          href="https://hbr.org/2015/10/the-changing-rules-of-trust-in-the-digital-age"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Racheal Botsman&nbsp;
        </a>
        it all begins with a progression in our understanding of ‘trust’. According
        to Botsman, we are beginning to move from an institutional system of trust to
        a distributed system, a natural progression in her eyes as “institutional trust
        is not designed for the digital age”. One example of a distributed trust system
        Botsman identifies is the technology underpinning the latest in Fintech, and which
        has huge potential for the food industry, the blockchain.
      </p>
      <p>
        The blockchain was developed as a decentralized ledger which records
        transactions and stores this information on a global network in a manner
        which prevents it being changed at a future point. While initially adopted
        for it’s financial implications, the blockchains’ decentralized system has
        huge potential for the traceability of supply chains.
      </p>
      <p>
        The blockchain provides a neutral open platform, there is no third party
        needed to authorize transactions, but rather a set of rules all participants,
        both users and the operators of the system, must abide by.
      </p>
      <p>
        Such a system is invaluable in complex supply chains were trust is low and
        compliance difficult to assess. It is no wonder then that The Economist
        dubbed the blockchain
        <a
          href="http://www.economist.com/news/leaders/21677198-technology-behind-bitcoin-could-transform-how-economy-works-trust-machine"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;“The Trust Machine”.
        </a>
      </p>
      <p>
        This brings huge advantages for every actor within the supply chain. For
        food producers, the blockchain means that any attempts to tamper with a
        food item as it moves through the supply chain can be immediately identified
        and prevented before the food ever reaches the retailer.
      </p>
      <p>
        For retailers, if a potentially hazardous food product somehow makes it onto
        shelves, stores can identify and remove only the offending items, eliminating
        the need for costly batch recalls.
      </p>
      <p>
        For consumers, the blockchain offers the transparency and openness needed to
        reassure them that the food they eat is exactly what the label says it is.
        The ability for consumers to identify high quality food is currently
        prohibited by information asymmetry.
      </p>
      <p>
        <a
          href="https://www.researchgate.net/publication/23512107_Consumer_demand_for_traceability"
          target="_blank"
          rel="noopener noreferrer"
        >
          Academic research
        </a>
        &nbsp;
        has pointed out that this asymmetry can cause failure in the market, with the risk of
        consumers adversely selecting lower quality (or unsafe) food in the absence of
        satisfactory information relating to food quality.
      </p>

      <p>
        The blockchain has the potential to take the power of information from
        Big Food and place it into the hands of the direct customer. Through
        the use of a simple QR code and a smartphone, customers can scan a
        package at the Point of Sale and receive a full and complete history
        of their foods journey from Farm to Fork.
      </p>
      <p>
        This is particularly useful in the grey areas of food traceability,
        such as country of origin labelling. This is an element of food information
        where it can be difficult to differentiate true claims from false, for
        instance a product can claim to be British pork when in reality it is
        for example, French in origin and subsequently processed in the UK.
      </p>
      <p>
        The blockchain is a useful tool here as it records each interaction
        with an item and assigns it a digital certificate, meaning it can not be
        changed or adulterated later by a company seeking to hide the true
        origin and movement of the product through the chain.
      </p>
      <p>
        This represents a huge opportunity for those companies who see the
        advantage of early adoption of blockchain infused traceability systems. Indeed by 2022,
        <a
          href="http://linkis.com/www.econotimes.com/Zk8mh"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gartner estimates
        </a>
        an innovative business built on a blockchain will be worth $10 billion.
      </p>
      <p>
        <a
          href="https://www.pwc.com/us/en/issues/food-trust/assets/food-supply-and-integrity-services.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Consumer trust in food is already damaged&nbsp;
        </a>
        and demand for full traceability is only
        <a
          href="https://www.labelinsight.com/transparency-roi-study"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp; growing stronger.&nbsp;
        </a>
        Companies can no longer rely on generalized terms such as “healthy” or
        “organic” in order to signal value, the new generation of
        <a
          href="http://www.theglobeandmail.com/news/national/how-the-food-industry-is-using-canadians-changing-eating-habits-to-market-to-different-generations/article32316327/"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;consumers are savvy enough to seek reassurances of quality past
          just what is printed on the label.&nbsp;
        </a>
      </p>
      <p>
        The blockchain provides a method of substantiating these previously unsubstantiated
        claims, strengthening customer loyalty for those companies who can consistently
        guarantee quality.
      </p>
      <p>
        In an increasingly volatile market, the blockchain adds an extra level of
        security for the food industry. Companies who can utilize the blockchain
        to instill transparency in their supply chains already have an insurance
        policy should another scandal hit the industry.
      </p>
      <p>
        It is already clear that the tide is turning towards traceability.
        Food producers who bury their head to block out the calls for more
        consumer empowerment do so at their peril, the rise of the blockchain
        means that those who answer the call put themselves at the top of the
        food chain.
      </p>
    </NewsTemplate>
  );
}
