import React from 'react';
import '../Vacancy.css';
import Fade from 'react-reveal/Fade';
import VacancyTemplate from '../../common/VacancyTemplate';
import separator from '../../../img/icons/paragraph-separator.svg';

const JuniorDesigner = () => {
  const jobTitle = 'Junior UI Designer';
  const jobDescription = `Arc-net is looking for a talented Junior UI Designer to
  join our growing development team in the centre of Edinburgh.`;
  return (
    <VacancyTemplate
      jobTitle={jobTitle}
      jobDescription={jobDescription}
    >
      <Fade bottom>
        <h1 className="bottom-header-text">Who we are</h1>
      </Fade>
      <Fade>
        <p className="bottom-text">
          Arc-net is a tech startup based in Edinburgh, the beautiful capital in the heart of Scotland.
          At arc-net, we are dedicated to providing transparency and security to supply chains, bringing
          deeper consumer engagement and data led business intelligence to organisations. We’re carving
          out a new space in some old industries and that requires both creativity and resilience.
          Working in anything from aviation to whisky, we love what we do here and we think you will too!
        </p>

        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">The role</h1>
      </Fade>
      <Fade>
        <p className="bottom-text">
          We’re looking for a keen and dynamic Junior UI Designer to join our team.
          As a Junior UI Designer, you will be involved in a wide range of creative
          projects, creating and championing digital experiences. You will sketch,
          prototype and wireframe designs that clearly communicate your ideas to
          help solve complex problems in an intense, highly collaborative manner.
          This role is an exciting opportunity to influence the design decisions
          related to core features and functions of our products. Working closely
          with the UI/UX Design Lead you&apos;ll also receive support and mentoring to
          help you grow professionally.
        </p>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">About you</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>
            You will ideally have at least 1 year experience in digital design, preferably with a
            degree level qualification
          </li>
          <li>
            You have a portfolio showcasing your strong conceptual and critical thinking
          </li>
          <li>
            You want to take your career to the next level by doing good work that makes a real
            difference, with the support of the UI/UX Design Lead
          </li>
          <li>
            You have a solid experience in user interface design
          </li>
          <li>
            You have a desire to understand and design for real human needs and behaviours
          </li>
          <li>
            You are able to make data-driven decisions informed by user testing and research
          </li>
          <li>
            You have experience with tools such as Sketch, Invision, Principle, Adobe Photoshop,
            XD, Adobe Animate, After Effects
          </li>
          <li>
            You understand form follows function
          </li>
          <li>
            You have the ability to get under the skin of our client’s businesses; understanding
            their needs and brand guidelines
          </li>
          <li>
            You are already comfortable writing HTML and CSS or have the desire to learn
          </li>
        </ul>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">You will also need to be</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>Self-motivated with the ability to multi-task and work in an often fast-paced environment</li>
          <li>Able to handle and process inputs and requests from many different areas</li>
          <li>Enthusiastic and motivated, with an appetite for learning</li>
          <li>Good at communicating, providing and receiving feedback</li>
          <li>Organised and good at prioritising work</li>
          <li>Focused, with sharp attention to detail</li>
          <li>Unafraid to ask questions</li>
        </ul>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">What you can expect from us</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>Salary of £28,000 per annum</li>
          <li>Great Edinburgh location</li>
          <li>Dynamic start-up culture</li>
          <li>Flexible working environment (including remote working where required)</li>
          <li>An open and welcoming culture with a great appetite for new technology and ideas</li>
        </ul>
        <p className="end-text">
          If this describes you, we&apos;d love to hear from you! All applications should include
          a portfolio showcasing your best UI design work, CV and a cover letter as to why
          you think you would be a good fit for arc-net.
        </p>
      </Fade>
    </VacancyTemplate>
  );
};

export default JuniorDesigner;
