import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import './NewsTemplate.css';
import { Container, Row, Col } from 'react-bootstrap';
import squareIcon from '../../img/blog/square-icon.svg';

export default function NewsTemplate(props) {
  const {
    heading, image, children, next, previous,
  } = props;
  return (
    <div className="news-template">
      <Fade>
        <Container fluid className="news-heading">
          <Row className="header">
            <Col className="col-header" md={{ span: 6, offset: 3 }} xs={{ span: 10, offset: 1 }}>
              <h2>{heading}</h2>
            </Col>
          </Row>
        </Container>
        <Container>
          <div
            style={{ backgroundImage: `url(${image})` }}
            src={image}
            className="header-image"
            alt="heading"
          />
          <hr />
          <div className="article">
            <Row>
              <Col className="text-column" md={{ span: 8, offset: 2 }} xs={12}>
                <Fade delay={500}>{children}</Fade>

                <div className="social-links">
                  <h5>SHARE</h5>
                  <div className="social-row">
                    <a
                      href={`http://twitter.com/share?text=@arcnetHQ&url=${window.location.href}`}
                      className="link"
                    >
                      Twitter
                    </a>
                    <a
                      href={`https://www.linkedin.com/shareArticle?mini=true&url=${
                        window.location.href
                      }`}
                      className="link"
                    >
                      LinkedIn
                    </a>
                    <a href="https://www.facebook.com/sharer.php?s=100" className="link">
                      Facebook
                    </a>
                  </div>
                </div>

                <div className="border-div">
                  <img src={squareIcon} alt="" className="square" />
                </div>
                <div className="navigation-div">
                  {previous ? (
                    <Link to={`/news/${previous}`} className="featured-link">
                      <div className="previous">Previous</div>
                    </Link>
                  ) : (
                    <div className="previous-inactive">Previous</div>
                  )}

                  {next ? (
                    <Link to={`/news/${next}`} className="featured-link">
                      <div className="next">Next</div>
                    </Link>
                  ) : (
                    <div className="next-inactive">Next</div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Fade>
    </div>
  );
}
