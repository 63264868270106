/* eslint-disable global-require */
import React from 'react';
import NewsTemplate from '../components/common/NewsTemplate';

export default function blockchain(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Arc-net participates in PwC hosted 'Blockchain applied in food' seminar"
      image={require('../img/blog/blog-blockchain-applied-square@2x.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        Arc-net was thrilled to participate in the Blockchain applied in food seminar hosted by PwC
        where we addressed the the key benefits that Blockchain technology can bring to business.
      </p>
      <img
        style={{ maxWidth: '100%' }}
        src={require('../img/blog/blockchain.png')}
        alt="blockchain"
      />
    </NewsTemplate>
  );
}
