import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, InputGroup, FormControl,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import logo from '../../img/arcnet-logo-blue.svg';
import arrow from '../../img/icons/arrow-right-icon.svg';

export default function Footer() {
  return (
    <div className="footer">
      <Container>
        <Row className="logo-row">
          <Col className="logo-col">
            <img src={logo} alt="arc-net logo" />
          </Col>
        </Row>
        <Row className="footer-row">
          <Col md={4} className="column-one">
            <h3>
              If you&apos;re interested in finding out how arc-net can help you transform your
              business,
              <Link to="/contact">get in touch</Link>
.
            </h3>
            <h4>
              <span>P: </span>
              +44 (0)131 2255 558
            </h4>
            <h4>
              <a href="mailto:info@arc-net.io">
                <span>E: </span>
                info@arc-net.io
              </a>
            </h4>
            <h3 id="address-one">No. 2 Lochrin Square, 96 Fountainbridge</h3>
            <h3 id="address-two">Edinburgh, EH3 9QA</h3>
            <p className="title" id="follow">
              Follow arc-net:
            </p>
            <ul>
              <li>
                <a href="https://twitter.com/arcnetHQ" target="_blank" rel="noopener noreferrer">
                  Tw
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/distilledID/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Fb
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/arc-net-ltd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  In
                </a>
              </li>
            </ul>
          </Col>
          <Col md={2} className="pages-wrapper quick-links">
            <p className="title"> Quick links</p>
            <ul>
              <LinkContainer to="/platform">
                <li>Platform</li>
              </LinkContainer>
              <LinkContainer to={{ pathname: '/', hash: 'products' }}>
                <li>Products</li>
              </LinkContainer>
              <LinkContainer to="/news">
                <li>News</li>
              </LinkContainer>
              <LinkContainer to="/about">
                <li>About</li>
              </LinkContainer>
              <LinkContainer to="/contact">
                <li>Contact</li>
              </LinkContainer>
              <LinkContainer to="/privacy-policy">
                <li>Legal Notice</li>
              </LinkContainer>
            </ul>
          </Col>
          <Col md={6} className="column-three">
            <p className="title">Our mailing lists</p>
            <h3>
              Sign up to our newsletter to receive information about our latest developments, news
              and events.
            </h3>
            <form
              action="https://arc-net.us15.list-manage.com/subscribe/post?u=187d76f3671d5e99eee582cbd&amp;id=1a63457dfb"
              method="post"
            >
              <InputGroup>
                <FormControl
                  className="form-field"
                  placeholder="yourname@email.com"
                  type="text"
                  id="mce-EMAIL"
                  name="EMAIL"
                />
                <InputGroup.Append>
                  <InputGroup.Text>
                    <button type="submit" id="email-button">
                      <img src={arrow} alt="" />
                    </button>
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </form>
            <p className="title" id="join-the-team">
              Join the team
            </p>
            <h3>Be a part of the digital revolution</h3>
            <Link to="/vacancy/software-engineer">
              <button type="button">Software Engineer</button>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
