import React from 'react';
import NewsTemplate from '../components/common/NewsTemplate';

export default function meetAnnette(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Meet Annette - our QA Manager"
      image={require('../img/blog/annette-profile.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        This blog post was written by Annette Tran, our QA Manager who wanted to share the
        experience of her first two weeks testing our products.
      </p>
      <p>
        Having spent the best part of my QA career working for large financial corporations, moving
        to a tech start up was a potentially risky career move. However, I have found the change in
        pace and environment most refreshing and I’m certain I made the right decision to jump when
        I did. It’s obvious that it’s a tight-knit team here at Arc-net however they were all very
        welcoming towards me and I felt at ease immediately. I find the platform and what they plan
        to do with it very interesting and something I can get on board with. There’s a lot of new
        tech and concepts for me to learn but I feel I have the freedom to explore, and fail safely!
        Already I have experimented with some new tools, something which I probably wouldn’t have
        had the time or space to do in previous roles.
        {' '}
      </p>
      <p>
        Day one was spent setting up my equipment, getting the right tools installed and ensuring
        that I was up and running on all the appropriate systems. Big shout-out to Alex who spent
        most of the day (patiently) helping me out! Day two, they let me loose on the Distillery
        Management Platform and I had raised a few bugs by the end of the day. I continued in this
        vein and found a few more bugs but then decided to take a break and switch my focus to
        looking at some non-functional aspects such as accessibility and client side performance
        analysis. I even had the opportunity to sit in on a client meeting which was great. As a QA
        professional, getting closer to the customer is highly beneficial as it helps me understand
        the typical use cases. As an employee, it felt good to be included.
        {' '}
      </p>
      <p>
        My plan going forward is to review the test strategy with a view to helping the team fill
        some gaps so that we can improve the quality of our offering even further. I’m also looking
        forward to up-skilling myself and really exercising my testing muscles. Exciting times ahead
        for arc-net and I’m honoured to part of the journey.
      </p>
      <p className="footnote">
        Annette, we are thrilled to welcome you into the arc-net team and impressed with the speed
        at which you’ve hit the ground running. We are positive that your insight and expertise will
        prove invaluable moving forward, especially at this exciting time in the arc-net roadmap!
      </p>
    </NewsTemplate>
  );
}
