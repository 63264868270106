import React from 'react';
import '../Vacancy.css';
import Fade from 'react-reveal/Fade';
import VacancyTemplate from '../../common/VacancyTemplate';
import separator from '../../../img/icons/paragraph-separator.svg';

const BusinessAnalyst = () => {
  const jobTitle = 'Business Analyst';
  const jobDescription = `Arc-net is looking for a talented
  Business Analyst to join our growing development team
  in Edinburgh.`;
  return (
    <VacancyTemplate
      jobTitle={jobTitle}
      jobDescription={jobDescription}
    >
      <Fade bottom>
        <h1 className="bottom-header-text">Who we are</h1>
      </Fade>
      <Fade>
        <p className="bottom-text">
          Arc-net is a tech startup based in Edinburgh, the beautiful capital in the heart of
          Scotland. We are using blockchain to help food & drink producers better understand their
          supply chain and customers. Our end-to-end platform provides full traceability, process
          control analytics and compliance support. In 2017, we launched the world’s first
          blockchain-enabled beer, allowing the brewer and customers to connect in a way not
          possible before, and we are doing the same with Scottish whisky. We are now scaling our
          platform for use in other areas of the food and drink industry, working towards our goal
          of bringing consumers and producers closer together.
        </p>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">Your key accountabilities</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>
            Consulting with our customers to capture, validate and document their business
            requirements.
          </li>
          <li>
            Investigate feasibility of potential new business in coordination with the business
            development manager role.
          </li>
          <li>
            Project management and planning of multiple projects simultaneously to ensure customer
            expectations are set and managed.
          </li>
          <li>Liasing with the technical teams to clearly articulate business requirements.</li>
          <li>Proactively identify and help define new features to fill perceived gaps in our offering.</li>
        </ul>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">Your key competencies</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>
            Strong inter-personal skills, including listening, engaging, negotiating and
            inspiring.
          </li>
          <li>
            Excellent ability to analyse problems and propose clear and effective solutions.
          </li>
          <li>A good understanding of technology solutions as a service.</li>
          <li>Quick and innovative thinker.</li>
          <li>Self-starter, able to work without direct supervision.</li>
          <li>Highly motivated and resilient.</li>
          <li>Commitment to our values and behaviours.</li>
        </ul>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">Nice to have</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>
            Agri-food experience, preferably operational/R&D/production/supply chain in nature.
          </li>
        </ul>
        <p className="end-text">Starting salary: £40,000</p>
      </Fade>
    </VacancyTemplate>
  );
};

export default BusinessAnalyst;
