import React from 'react';
import { Link } from 'react-router-dom';
import NewsTemplate from '../components/common/NewsTemplate';

export default function brexit(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Brexit and UK Agri-food"
      image={require('../img/blog/blog-brexit-and-uk-agrifood-square@2x.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>Dr Stephanie Brooks arc-net Senior Consultant</p>
      <p>
        Whether you voted to remain or leave, Brexit will ultimately mean change for everyone.
        Following the UK’s decision to leave the EU in June 2016, the changes that will be evoked
        are still unclear. While concerns centred around travel and freedom of movement of people
        (as well as wondering ‘Will I need a visa to go to Spain?’), are likely the primary concern
        of most people, less thought has been given to the potential devastating impacts on trade of
        their much-loved goods. Essential to existence of humans is food, and preceding food,
        agriculture. Brexit has the potential to heavily impact UK agri-food trade in a number of
        important ways.
      </p>
      <p>
        Self-sufficiency in agri-food is not a prospect the UK has been close to in some time. Some
        may blame the existence of the EU for that, where the free trade has meant self-sufficiency
        was not required, nor financially desirable if industries could return more profit selling
        outside of home nations. The pro’s and the perceived need for self-sufficiency in food may
        have formed part of the ‘leave’ voters’ decision making process but, ultimately food (and
        the impact of Brexit on food) was not considered in the leave campaign and the general
        public kept in the dark on the status of post Brexit food. The leave campaign pushed the
        potential opportunities for immigration policy reform and the promise of the UK no longer
        being a net ‘giver’ of money to the EU, over food; the one thing all voters require and can
        relate to.
      </p>
      <p>
        The fact of the matter is that the UK has a major trade deficit for agri-food products. The
        UK exports £18.8 bn of food and imports £39.5 bn; this equates to a £20.6 bn trade deficit
        [1]. In other words, the UK relies heavily on other countries to supply them with food;
        that’s a 46% reliance on other countries to be precise [1]. The UK is only 54%
        self-sufficient, and the majority of the reliance (46%) resides with other EU countries
        (27%) [1].
      </p>
      <p>
        I know what you are thinking, ‘Sure we can just continue to import food from the EU and
        other countries.’ In a word, yes we could, however, being a member of the EU entitles the UK
        to move goods TARIFF (a form of tax on goods) FREE and countries outside the EU have to pay
        tariffs (in line with World Trade Organisation rules) on their goods they are importing into
        the EU. The EU tariff wall has ultimately protected Europe from cheaper imports from around
        the world by imposing heavy tariffs on non EU importing countries. Worst case scenario when
        the UK leaves the EU, it is plausible that any exports to EU countries from the UK will be
        subject to tariffs. In retaliation, it is also plausible that the UK will impose tariffs on
        EU goods (which don’t forget we are heavily reliant on) being imported to the UK. We enter
        into a situation of additional taxes toing and froing between the UK and EU, and ultimately,
        we will still be paying the EU in some form or fashion.
      </p>
      <p>
        It isn’t all doom and gloom, tariffs can be reduced or eliminated if a trade deal between
        the UK with a particular country (or the EU as a whole) is struck. However, my PhD research
        revealed a lack of confidence in the Conservative government to act in the best interests of
        UK agriculture and food. If there was a trade deal done with a third (non-EU) country, we
        have to anticipate what that means for UK agri-food. The EU has been incremental in
        improving animal welfare standards, food safety standards and environmental standards,
        therefore EU food is produced to high standards. Any trade deal with a third country may
        mean that the UK would have to be prepared to provide access to third country agri-food
        products. Cue the possibility of a flooded UK market with cheaper and lower standard/quality
        third country imports. For UK agri-food this could be particularly negative, as the UK would
        be unable to compete with cheaper imports on an economies of scale platform, where these
        imports are perceived to have lower cost of production and lower standards and quality. For
        consumers, lower prices may appear on the surface to be favourable but are consumers willing
        to accept lower prices for lower standards (lower welfare, hormone use in livestock
        production, poorer environmental standards, Genetically Modified produce) that the EU has
        protected them from?
      </p>
      <p>
        Farmers in the EU receive direct subsidies to support production of EU food. These subsidies
        have ultimately helped support farmers and even kept some in business. While you could argue
        that subsidies only help prop up inefficient and unprofitable farming systems, you have to
        pay gratitude to subsidies for ultimately making food on the supermarket shelve affordable.
        Without subsidies in the UK the cost of production would be substantially higher, therefore
        to get a sufficient return, retail prices would have to be proportionately higher. This
        raise the question of whether cheaper to produce and lower quality/standard imports will
        triumph over locally, home grown and produce food which is set to be potentially more
        expensive (due to higher production costs), or will the UK consumer remain commitment to
        local, British produce, even if it means a higher price?
      </p>
      <p>
        Furthermore, the reliance of retailers on EU produce to ensure appropriate and year round
        supply of food may end up subject to tariffs, that could also increase the cost of food. The
        British Retail Consortium reported that food could rise between 5 and 30%, depending on the
        commodity, [2]. Michael Gove (the Conservative Environment Secretary) suggested that same
        level of subsidies will still be available following Brexit until 2022 [3] but what exactly
        that structure looks like is still unclear, i.e. on what basis will subsidies be paid?
        Surprisingly, many farmers voted to leave the EU. Part of this may be attributable to the
        ‘one size fits all’ policy approach of the EU, which has its own inherent drawbacks. This is
        where I believe Brexit presents the most opportunity; to re write agricultural policy to
        better suit the UK, however caution should be exercised. The UK is made up of 4 devolved
        regions each of which as their own agricultural needs, therefore we do not want to run the
        risk of implementing a ‘one size fits all’ approach again.
      </p>
      <p>
        The UK agri-food industry is heavily reliant on migrant labour to staff farms and factories
        that produce and process food. Without migrant labour, British produce is in jeopardy.
        Another unknown of Brexit is what will happen to the migrant labour? Will EU migrants be
        expected to leave the UK when it exits the EU, and if so, how will factories and farms in
        the UK continue to operate? Will UK produce suffer and will we become more reliant on
        imports than we have ever been?
      </p>
      <p>
        The unknowns of Brexit and the inability to provide any clarifications at Government level
        before the UK settles its ‘divorce bill’ with the EU, adds to much of the unease in the
        agri-food industry. The agri-food sector are unable to make strategic decisions for the
        future with the level of uncertainty that exists. For example, should processors continue to
        invest in their UK factories, should the industry in general examine the possibility of
        abandoning servicing the home market and creating a niche, concentrating on high value
        export markets? With this level of uncertainty, it is difficult to make any long term,
        meaningful plans for the future of food in the UK, and that’s a scary thought when food
        means existence and is the one thing that everyone has in common whether you are a ‘leaver’
        or a ‘remainer’.
      </p>
      <p>
        <a
          href="http://openaccess.city.ac.uk/14896/1/FRC%20Brexit%2007%2003%2016.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          [1] http://openaccess.city.ac.uk/14896/1/FRC%20Brexit%2007%2003%2016.pdf
        </a>
      </p>
      <p>
        <a
          href="https://www.talkingretail.com/news/industry-news/food-prices-rise-30-no-brexit-deal-reached-warns-brc-18-09-2017/"
          target="_blank"
          rel="noopener noreferrer"
        >
          [2]
          https://www.talkingretail.com/news/industry-news/food-prices-rise-30-no-brexit-deal-reached-warns-brc-18-09-2017/
        </a>
      </p>
      <p>
        <a
          href="http://www.bbc.co.uk/news/science-environment-40673559"
          target="_blank"
          rel="noopener noreferrer"
        >
          [3] http://www.bbc.co.uk/news/science-environment-40673559
        </a>
      </p>
      <p>
        Want to know more about protecting your business from risk?
        {' '}
        <Link to="/contact">Get in touch with arc-net today</Link>
        {' '}
      </p>
    </NewsTemplate>
  );
}
