import React, { Component } from 'react';
import {
  Route, Switch, BrowserRouter, Redirect,
} from 'react-router-dom';
import Home from './components/views/Home';
import Layout from './components/common/Layout';
import Contact from './components/views/Contact';
import Demo from './components/views/Demo';
import News from './components/views/News';
import NewsRoute from './components/common/NewsRoute';
import Company from './components/views/Company';
import Platform from './components/views/Platform';
import Privacy from './components/views/Privacy';
import ScrollToTop from './components/common/ScrollToTop';
import BusinessAnalyst from './components/views/positions/BusinessAnalyst';
import BusinessDevelopmentManager from './components/views/positions/BusinessDevelopmentManager';
import InfrastructureEngineer from './components/views/positions/InfrastructureEngineer';
import Engineer from './components/views/positions/Engineer';
import JuniorUIDesigner from './components/views/positions/JuniorUIDesigner';
import JuniorQAEngineer from './components/views/positions/JuniorQAEngineer';

import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <ScrollToTop>
            <Layout>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/contact" component={Contact} />
                <Route path="/demo" component={Demo} />
                <Route path="/news/:postID" component={NewsRoute} />
                <Route path="/news" component={News} />
                <Route path="/vacancy/business-analyst" component={BusinessAnalyst} />
                <Route
                  path="/vacancy/business-development-manager"
                  component={BusinessDevelopmentManager}
                />
                <Route path="/vacancy/infrastructure-engineer" component={InfrastructureEngineer} />
                <Route path="/vacancy/software-engineer" component={Engineer} />
                <Route path="/vacancy/junior-ui-designer" component={JuniorUIDesigner} />
                <Route path="/vacancy/junior-qa-engineer" component={JuniorQAEngineer} />
                <Route path="/about" component={Company} />
                <Route path="/platform" component={Platform} />
                <Route path="/privacy-policy" component={Privacy} />
                <Route render={() => <Redirect to="/" />} />
              </Switch>
            </Layout>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
