import React from 'react';
import './TabDistilleries.css';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Pulse from 'react-reveal/Pulse';
import Ailsa from '../../img/products/distilled-id/case-study-ailsa-bay.jpg';
import Icb from '../../img/products/distilled-id/case-study-icb.jpg';
import Arrow from '../../img/icons/arrow-right-icon.svg';
import DistilledLogo from '../../img/products/distilled-id/distilled-ID.svg';

const renderPartnership = (title, content, image, link) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <div className="partnership">
      <div style={{ backgroundImage: `url(${image})` }} className="image-div" />
      <div className="text">
        <h2>{title}</h2>
        <p>{content}</p>
      </div>
      <img src={Arrow} className="arrow" alt="" />
    </div>
  </a>
);

export default function TabDistilleries() {
  return (
    <div className="tab-distilleries">
      <Container style={{ maxWidth: '1440px' }}>
        <Row className="distilled-row">
          <Col className="left-col" lg={{ span: 7, offset: 0 }}>
            <Fade bottom>
              <h2 id="distilled-title">Introducing Distilled ID</h2>
            </Fade>
            <p className="first-para">
              Distilled ID brings arc-net into the heart of your distillery connecting your brand
              with the customers who love it.
            </p>
            <p>
              Our distillery management product extends our core traceability solution providing you
              with the means to control and track your unique spirit from grain to glass and beyond,
              sharing your brand story with the world and building valuable customer engagement.
            </p>
            <p id="trusted">Trusted by:</p>
            <div className="logos">
              <div id="logo2" className="logo-image" />
              <div id="logo3" className="logo-image" />
              <div id="logo4" className="logo-image" />
              <div id="logo5" className="logo-image" />
            </div>
          </Col>
          <Col className="right-col" lg={{ span: 5, offset: 0 }}>
            <Slide right>
              <div className="distilled-inset">
                <Fade delay={1000} duration={2000}>
                  <div className="get-started">
                    <h5>Get started with</h5>
                    <img className="logo" src={DistilledLogo} alt="" />
                    <div className="register">
                      <Link to="/demo" className="arrow-link-wrapper">
                        <h3 className="arrow-link light">REGISTER</h3>
                      </Link>
                    </div>
                  </div>
                </Fade>
              </div>
            </Slide>
          </Col>
        </Row>
        <Row className="consumer-row">
          <Col className="left-col" xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
            <Slide left>
              <div id="consumer-image">
                <Fade delay={1000} duration={2000}>
                  <div className="ailsa-image-wrapper">
                    <div id="ailsa-image" alt="ailsa-bay" />
                  </div>
                </Fade>
              </div>
            </Slide>
          </Col>
          <Col className="right-col" xs={{ span: 12, order: 1 }} lg={{ span: 5, order: 2 }}>
            <Fade bottom>
              <h2>Storybook with Consumer Intelligence</h2>
            </Fade>
            <Fade bottom delay={500}>
              <div className="border-gradient" />
            </Fade>
            <Fade>
              <p className="first-para">
                Your Storybook is a rich, interactive method for you to share your product and brand
                story with your customers.
              </p>
              <p>
                Share selections of your production data, right from your private blockchain,
                helping your customers understand your product’s taste, heritage, sustainability
                profile and, in doing so, gain hugely valuable insight into their behaviour. The
                arc-net analytics engine can bring you location data, consumer profiles, frequency
                of engagement, individual product data and much more.
              </p>
            </Fade>
            <Fade delay={300}>
              <Pulse delay={1000} count={2}>
                <a
                  href="https://distilledid.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="arrow-link-wrapper"
                >
                  <h3 className="arrow-link dark">DISCOVER </h3>
                </a>
              </Pulse>
            </Fade>
          </Col>
        </Row>
        <Row className="dmp-row">
          <Col className="left-col" lg={{ span: 4, offset: 1 }}>
            <Fade bottom>
              <h2>Distillery Management Platform</h2>
            </Fade>
            <Fade delay={500}>
              <div className="border-gradient" />
            </Fade>
            <Fade>
              <p className="first-para">
                The Distilled ID platform helps you manage every aspect of your distillery from the
                arrival of stock through spirit production and storage, right up to bottling and
                distribution.
              </p>
              <p>
                Using our unique blockchain enabled service, you can track every step of your
                process and verify the integrity and validity of the data. Sharing this data with
                your customers using our “Storybook” interface helps improve trust in your brand
                while also creating a connection with your product’s consumers in a way that was
                previously impossible.
              </p>
            </Fade>
            <Fade delay={300}>
              <Pulse delay={1000} count={2}>
                <a
                  href="https://distilledid.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="arrow-link-wrapper"
                >
                  <h3 className="arrow-link dark">DISCOVER </h3>
                </a>
              </Pulse>
            </Fade>
          </Col>
          <Col className="right-col" lg={7}>
            <Slide right>
              <div id="distillery-image">
                <Fade delay={1000} duration={2000}>
                  <div className="platform-image-wrapper">
                    <div id="platform-image" alt="platform" />
                  </div>
                </Fade>
              </div>
            </Slide>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }} className="distillery-text">
            <Fade>
              Distilled ID Storybooks around the world
              <div id="border-top" />
            </Fade>
          </Col>
        </Row>
        <Row className="stats-row">
          <Col className="stats-col" md={{ span: 6, offset: 3 }}>
            <Fade delay={150}>
              <div className="stat">
                <h2>53,920</h2>
                <h3>spirits tracked</h3>
              </div>
            </Fade>
            <Fade delay={300}>
              <div className="stat">
                <h2>20,327</h2>
                <h3>beers tracked</h3>
              </div>
            </Fade>
            <Fade delay={450}>
              <div className="stat">
                <h2 id="fifteen">15%</h2>
                <h3>bottle scan rate</h3>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Fade>
          <Row className="explore-partnerships">
            <Col md={{ span: 8, offset: 2 }} lg={{ span: 4, offset: 0 }} className="explore-col">
              Explore our recent partnerships
            </Col>
          </Row>
          <Row className="partnership-row">
            <Col
              md={{ span: 8, offset: 2 }}
              lg={{ span: 4, offset: 0 }}
              className="partnership-col"
            >
              {renderPartnership(
                'Ailsa Bay Storybook - yesterday’s traditions, tomorrow’s technology',
                'Produced and bottled utilising Distilled ID, arc-net’s unique blockchain-backed distillery management platform.',
                Ailsa,
                'https://distilledid.com/case_studies/ailsabay',
              )}
            </Col>
            <Col
              md={{ span: 8, offset: 2 }}
              lg={{ span: 4, offset: 0 }}
              className="partnership-col"
            >
              {renderPartnership(
                'Ireland Craft Beers - The world’s first blockchain beer',
                'Full traceability of every bottle through the brewery and the supplier network to the consumer.',
                Icb,
                'https://distilledid.com/case_studies/irelandcraftbeers',
              )}
            </Col>
          </Row>
        </Fade>
      </Container>
    </div>
  );
}
