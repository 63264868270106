import React from 'react';
import NewsTemplate from '../components/common/NewsTemplate';

export default function craftBeer(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Arc-net announce ‘world-first’ craft beer initiative."
      image={require('../img/blog/icb-sq.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>Beer ‘world-first’ by two innovative Northern Ireland companies.</p>
      <p>E Spark firms join to put new craft brew on the blockchain</p>
      <p>
        A world-first has been achieved by two Northern Ireland businesses that were brought
        together by the Ulster Bank-powered Entrepreneurial Spark hub in Belfast.
      </p>
      <p>
        Ireland Craft Beers and technology firm arc-net have collaborated to create a new beer brand
        that will be placed on a blockchain platform – the first time this has been done anywhere in
        the world - to enable consumers to fully trace the product’s ingredients from source.
      </p>
      <p>
        arc-net has uniquely marked and authenticated each bottle of the new Downstream IPL,
        allowing full traceability of each bottle through the brewery and the supplier network to
        the ultimate consumer.
      </p>
      <p>
        Customers need simply scan a QR smart code on the label of the beer to be taken to an online
        experience that allows them to uniquely identify every single bottle produced.
      </p>
      <p>
        Ireland Craft Beers has already secured a listing for the new beer in the popular Honest
        Brew online store, as well with a major multi-national retailer that is expected to stock
        the beer in more than 400 stores across the UK.
      </p>
      <p>
        The product is being listed with Honest Brew this week, with the listing in the
        multi-national retailer to follow in the new year.
      </p>
      <p>
        Lynsey Cunningham, Entrepreneurial Development Manager at Ulster Bank, says: “Ireland Craft
        Beers and arc-net came together through their involvement with Entrepreneurial Spark, and
        are demonstrating the power of collaboration. This is an innovative venture that has the
        potential to bring real change to how consumers engage with information about the products
        they buy.”
      </p>
      <p>
        Liam Brogan, Co-Founder and Director of Ireland Craft Beers, says: “More than ever,
        consumers want to know where their food and drink comes from, so capturing and sharing
        production, process and product data with customers is central to having a brand that is
        trusted and respected. Consumers want transparency, they want authenticity, and blockchain
        enables that.”
      </p>
      <p>
        Kieran Kelly, CEO of arc-net, said: “The arc-net platform provides Ireland Craft Beers (ICB)
        with the opportunity to tell the story behind their brand. The new era of transparency on
        the blockchain helps to ensure the people and process behind the craft brewing process is
        made available. In a crowded market, products on the arc-net blockchain now have a voice.
        Liam and Shane (ICB) have a vision to promote and protect the craft brewing process across
        the globe. arc-net is delighted to support ICB in their vision for the future and we are
        looking forward to building a sustainable platform to support the craft brewing community”.
      </p>
      <p>
        Ireland Craft Beers is a distributor of craft beers from the island of Ireland to export
        markets. It is working with Mourne Mountains Brewery to brew the new Downstream IPL.
      </p>
      <p>
        arc-net is an expert in blockchain technology. It was founded by Northern Ireland
        entrepreneur Kieran Kelly and has built the arc-net platform, which is a cloud based data
        analytics and supply-chain security platform for the food and drinks industry.
      </p>
      <p>
        Entrepreneurial Spark is a business accelerator and growth hub for entrepreneurs. It is part
        of a major international movement to encourage and support start-up and scale-up businesses.
      </p>
      <p>
        Powered by Ulster Bank, the Entrepreneurial Spark Hatchery in Belfast provides free space
        for up to 80 entrepreneurs, with hands-on mentoring, a ‘bootcamp’ for business planning, and
        a free programme of up to 18-months of advice, support and expert workshops.
      </p>
    </NewsTemplate>
  );
}
