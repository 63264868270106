import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import trace from '../../img/icons/trace-icon.svg';
import verify from '../../img/icons/verify-icon.svg';
import trust from '../../img/icons/trust-icon.svg';
import './Future.css';

export default function Future() {
  return (
    <div className="future">
      <Container>
        <Row>
          <Col lg={{ span: 10, offset: 1 }}>
            <Fade bottom>
              <h2>Let&apos;s create the future together</h2>
            </Fade>
            <Fade>
              <p>
                Combining the effectiveness of blockchain in relation to complex supply chains and
                digital asset tracking, as well as cutting edge web and mobile interface design,
                arc-net is developing the tool for the future of track and traceability.
              </p>
              <p>
                Unlocking business intelligence, supply chain security, demonstration of provenance
                and product authentication, the arc-net toolset will bring your supply chain into
                the present and prepare it for the future.
              </p>
            </Fade>
          </Col>
        </Row>

        <Row>
          <Col lg={{ span: 4 }}>
            <div className="benefit-wrapper">
              <img src={trace} className="feature-icon" alt="supply chain traceability" />
              <h3>Trace</h3>
              <h4>Control your chain</h4>
              <span>Deliver Total Traceability</span>
              <span>Cut Cost of Product Recalls</span>
              <span>Reduce Process Inefficiencies</span>
            </div>
          </Col>
          <Col lg={{ span: 4 }}>
            <div className="benefit-wrapper">
              <img src={verify} className="feature-icon" alt="verify authenticity" />
              <h3>Verify</h3>
              <h4>Enhance Your Business</h4>
              <span>Guarantee Authentic Product</span>
              <span>Enhance Existing Systems</span>
              <span>Provide Automatic Provenance</span>
            </div>
          </Col>
          <Col lg={{ span: 4 }}>
            <div className="benefit-wrapper">
              <img src={trust} className="feature-icon" alt="protect your brand" />
              <h3>Trust</h3>
              <h4>Protect Your Brand</h4>
              <span>Build Consumer Trust</span>
              <span>Wield Customer Influence</span>
              <span>Unlock Powerful Data</span>
            </div>
          </Col>
        </Row>

        <Link to="/contact/">
          <div className="get-in-touch-button button-hover">GET IN TOUCH</div>
        </Link>
      </Container>
    </div>
  );
}
