import './Home.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ControlledCarousel from '../common/ControlledCarousel';
import IconRow from '../common/IconRow';
import BrandProtection from '../common/BrandProtection';
import Future from '../common/Future';
import Solutions from '../common/Solutions';
import YourJourney from '../common/YourJourney';
import { toggleClick } from '../../store/actions';

class Home extends Component {
  componentDidMount() {
    const { clickState, togglePress, location } = this.props;
    if (clickState) {
      this.scrollTo();
      togglePress(false);
    }

    if (location.hash === '#products') {
      this.scrollTo();
    }
  }

  componentDidUpdate() {
    const { clickState, togglePress, location } = this.props;
    if (clickState) {
      this.scrollTo();
      togglePress(false);
    }

    if (location.hash === '#products') {
      this.scrollTo();
    }
  }

  scrollTo() {
    setTimeout(
      () => this.solutions.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      }),
      0,
    );
  }

  render() {
    const { location } = this.props;
    return (
      <div className="home">
        <ControlledCarousel />
        <IconRow />
        <BrandProtection />
        <div
          ref={(r) => {
            this.solutions = r;
          }}
        >
          <Solutions activeTab={location.hash} />
        </div>
        <YourJourney />
        <Future />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clickState: state.clicked,
    tabClickState: state.tabClick,
  };
}

const mapDispatchToProps = dispatch => ({
  togglePress: clickState => dispatch(toggleClick(clickState)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
