import './ControlledCarousel.css';
import React from 'react';
import { connect } from 'react-redux';
import { Carousel, Button } from 'react-bootstrap';
import ModalVideo from './ModalVideo';
import playIcon from '../../img/icons/play-icon.svg';
import { toggleClick, setActiveTab } from '../../store/actions';

class ControlledCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      timer: 0,
      showVideoPlayer: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.onClosePlayer = this.onClosePlayer.bind(this);
  }

  componentDidMount() {
    this.intervalCarousel = setInterval(() => this.cycleCarousel(), 5500);
    this.intervalClock = setInterval(() => this.setClock(), 100);
  }

  componentWillUnmount() {
    clearInterval(this.intervalCarousel);
    clearInterval(this.intervalClock);
  }

  onClickPlayer(e) {
    e.preventDefault();
    this.setState({ showVideoPlayer: true });
  }

  onClosePlayer() {
    this.setState({ showVideoPlayer: false });
  }

  setClock() {
    const { timer } = this.state;
    this.setState({ timer: timer + 100 });
  }

  cycleCarousel() {
    const { index } = this.state;
    if (index === 1) {
      this.setState({ index: 0, timer: 0 });
    } else {
      this.setState({ index: index + 1, timer: 0 });
    }
  }

  handleSelect(selectedIndex) {
    this.setState({
      index: selectedIndex,
      timer: 0,
    });
    clearInterval(this.intervalCarousel);
    clearInterval(this.intervalClock);
    this.intervalCarousel = setInterval(() => this.cycleCarousel(), 5000);
    this.intervalClock = setInterval(() => this.setClock(), 100);
  }

  renderButtons(brand) {
    const video = (
      <Button className="video-button" onClick={e => this.onClickPlayer(e)}>
        <img src={playIcon} className="play-icon" alt="play icon" />
        WATCH VIDEO
      </Button>
    );

    let videoDiv;

    switch (brand) {
      case 'distilledid':
        videoDiv = null;
        break;
      case 'harvestid':
        videoDiv = null;
        break;
      default:
        videoDiv = null;
    }

    const item = (
      <div className="button-div">
        <Button
          className="learn-button button-hover"
          onClick={() => {
            // eslint-disable-next-line react/destructuring-assignment
            this.props.toggleClick(true);
            // eslint-disable-next-line react/destructuring-assignment
            this.props.setActiveTab(`#${brand}`);
          }}
        >
          LEARN MORE
        </Button>
        {videoDiv}
      </div>
    );
    return item;
  }

  renderItem(brand) {
    let blurb;
    const distilleryBlurb = (
      <p>
        Track your unique spirit
        <span> from grain to glass and beyond </span>
        and share your tale with the world.
      </p>
    );
    const harvestBlurb = (
      <p>
        Connect
        <span> every step of your product's journey </span>
        to deliver supply chain transparency and product security.
      </p>
    );

    switch (brand) {
      case 'distilledid':
        blurb = distilleryBlurb;
        break;
      case 'harvestid':
        blurb = harvestBlurb;
        break;
      default:
        blurb = 'error';
    }

    const item = (
      <div className="carousel-container">
        <div
          style={
            window.innerWidth > 1340
              ? { paddingLeft: (window.innerWidth - 1340) / 2 + 32 }
              : { paddingLeft: '16px' }
          }
          className="carousel-left"
        >
          <div>
            <h1 id="blurb">{blurb}</h1>
            {this.renderButtons(brand)}
          </div>
        </div>
        <div className={`carousel-right ${brand}`} />
      </div>
    );
    return item;
  }

  render() {
    const { index, direction, showVideoPlayer } = this.state;
    return (
      <div className="carousel">
        <ModalVideo
          style={{ backgroundColor: 'transparent' }}
          show={showVideoPlayer}
          handleClose={this.onClosePlayer}
          url="https://vimeo.com/329533545"
          controls={false}
          height="70vh"
          width="auto"
        />
        <Carousel
          className="carousel-fade"
          indicators={false}
          controls={false}
          interval={null}
          activeIndex={index}
          direction={direction}
          onSelect={this.handleSelect}
        >
          <Carousel.Item>{this.renderItem('distilledid')}</Carousel.Item>
          <Carousel.Item>{this.renderItem('harvestid')}</Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clicked: state.clicked,
  activeTab: state.activeTab,
});

const mapDispatchToProps = dispatch => ({
  toggleClick: clickState => dispatch(toggleClick(clickState)),
  setActiveTab: activeTab => dispatch(setActiveTab(activeTab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlledCarousel);
