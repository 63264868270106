import React from 'react';
import './TabFood.css';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import harvestIDLogo from '../../img/products/harvest-id/harvest-ID.svg';
import FeatureRow from './FeatureRow';

export default function TabAviation() {
  const features = [
    {
      title: 'Anti-Counterfeit',
      content:
        'The data collected from the Storybooks can help you spot parallel trading vectors. Combine them with our tamper-evident NFC tags and we can make your products highly resistant to counterfeiting.',
    },
    {
      title: 'Targeted Marketing',
      content:
        'As your customers scan your products we build a picture of their purchasing behaviour. You can leverage this hugely valuable data to develop specifically targeted marketing, maximising its effectiveness.',
    },
    {
      title: 'Community Features',
      content:
        'Our more advanced Storybooks can be built on top of full community features allowing two way communication, user feedback, and rich consumer interactions.',
    },
  ];

  return (
    <div className="tab-food">
      <Container fluid className="max-width">
        <Row className="featured-row">
          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 5, order: 1 }}
            className="featured-img-wrapper"
          >
            <Slide left>
              <div className="harvest-inset">
                <Fade delay={1000}>
                  <div className="get-started">
                    <h5>Get started with</h5>
                    <img className="harvest-logo" src={harvestIDLogo} alt="" />
                    <div className="register">
                      <Link to="/demo" className="arrow-link-wrapper">
                        <h3 className="arrow-link light">REGISTER</h3>
                      </Link>
                    </div>
                  </div>
                </Fade>
              </div>
            </Slide>
          </Col>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} className="copy">
            <Fade bottom>
              <h2>Introducing Harvest ID</h2>
            </Fade>
            <Fade delay={200}>
              <h3>Arc-net has brought our trusted traceability solution to the food industry.</h3>
            </Fade>
            <Fade delay={400}>
              <p>
                Use precision farming techniques to track your arable and pastoral products through
                their complete lifecycle from growth to packaging, logistics and retail. Associate
                your meat products with DNA records or use GPS data to identify cereals from the
                field in which they were grown for maximum traceability. Share your data via our
                unique Storybook interfaces for new ways to engage with, and market to your
                customers.
              </p>
            </Fade>
            <Fade delay={600}>
              <p id="trusted">Trusted by:</p>
            </Fade>
            <Fade delay={600}>
              <div className="logos">
                <div id="logo1" className="logo" />
                <div id="logo2" className="logo" />
                <div id="logo3" className="logo" />
                <div id="logo4" className="logo" />
                <div id="logo5" className="logo" />
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="field-fork-row">
          <Col md={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }} className="copy">
            <Fade bottom>
              <h2>Field to Fork</h2>
            </Fade>
            <Fade delay={500}>
              <div className="border-gradient" />
            </Fade>
            <Fade>
              <p className="first-para">
                By tracking your food production events on our blockchain-enabled platform you will
                be able to securely demonstrate your unique brand provenance to your customers,
                increasing their loyalty and allowing them to trust in your quality produce.
              </p>
              <p>
                While your customers are demanding more transparency into your production, arc-net
                can provide you with deep insight into your own systems, unlocking the enormous
                value in your own data. By tracking your processes in arc-net we can help you spot
                bottle-necks and identify patterns in your data which will allow you to make
                adjustments on the fly and find new ways to access the value of your produce.
              </p>
            </Fade>
          </Col>
          <Col md={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
            <Slide right>
              <div id="field-image" alt="field" />
            </Slide>
          </Col>
        </Row>

        <Row className="consumer-row">
          <Col className="left-col" xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
            <Slide left>
              <div id="consumer-image">
                <Reveal effect="fadeIn" delay={1000} duration={2000}>
                  <div id="honey-image" />
                </Reveal>
              </div>
            </Slide>
          </Col>
          <Col className="right-col" xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 2 }}>
            <div>
              <Fade bottom>
                <h2>Consumer Experience</h2>
              </Fade>
              <Fade bottom delay={500}>
                <div className="border-gradient" />
              </Fade>
              <Fade>
                <p className="first-para">
                  QR-codes and NFC chip provide access to your Storybook. This unique per-product
                  interface is the means by which your customers discover your brand story.
                </p>
                <p>
                  Each product added to Harvest ID comes with a simple Storybook designed and built
                  by our in house team of experts and we will work with you to identify exactly
                  what, and how, you want to share your story with your customers. By investing in
                  this part of Harvest ID, you can access a hugely powerful toolset for your
                  organisation. The more advanced your Storybook becomes, the more we can unlock
                  with features ranging from interactive content to gamification and community
                  features.
                </p>
              </Fade>
            </div>
          </Col>
        </Row>
      </Container>
      <FeatureRow features={features} />
    </div>
  );
}
