import React, { Component } from 'react';
import './Contact.css';
import {
  Container, Row, Col, Button, Form,
} from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Map from '../common/Map';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      validName: true,
      email: '',
      validEmail: true,
      phone: '',
      validPhone: true,
      message: '',
      validMessage: true,
      validRadio: false,
    };
    this.submittedRef = React.createRef();
    this.scrollToRef = this.scrollToRef.bind(this);
  }

  scrollToRef() {
    window.scrollTo(0, this.scrollToRef);
  }

  isEmailValid(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }

  isFormValid() {
    const {
      name,
      email,
      phone,
      message,
      validName,
      validEmail,
      validPhone,
      validMessage,
      validRadio,
    } = this.state;
    const emptyForm = name === '' || email === '' || phone === '' || message === '';
    const valid = validName && validEmail && validPhone && validMessage && validRadio;
    return !(valid && !emptyForm);
  }

  render() {
    const {
      validName,
      name,
      validEmail,
      email,
      validPhone,
      phone,
      validMessage,
      message,
      industryEmail,
    } = this.state;

    const distilleryEmail = 'info@distilledid.com';
    const foodOtherEmail = 'info@arc-net.io';

    return (
      <div className="contact">
        <Row className="map-row">
          <Col md={12} className="map-col">
            <div className="map">
              <Map />
            </div>
          </Col>
        </Row>
        <Container fluid>
          <Row className="contact-row">
            <Col
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 6 }}
              className="col-one"
            >
              <Fade>
                <h1>Get in touch</h1>
                <h3 className="question">
                  Do you have a question about one of our platforms, arc-net
                  solution or services? We&apos;d love to hear from you. Fill
                  out the form to the right and we will be in touch.
                </h3>
                <h2>Need to speak to us sooner?</h2>
                <div className="block">
                  <h3>We&apos;re here Monday-Friday 9AM to 5PM GMT.</h3>
                </div>
                <div className="block-two">
                  <h3>Call us at +44 (0) 131 2255 558</h3>
                  <h3>
                    Email us at
                    <a href="mailto:info@arc-net.io"> info@arc-net.io</a>
                  </h3>
                </div>
                <h2>Office location</h2>
                <Row>
                  <Col lg={{ span: 4 }}>
                    <div className="block block-three">
                      <h3 className="location">arc-net HQ</h3>
                      <h3>No.2 Lochrin Square,</h3>
                      <h3>96 Fountainbridge</h3>
                      <h3>Edinburgh, EH3 9QA</h3>
                    </div>
                  </Col>
                </Row>
              </Fade>
            </Col>
            <Col
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 5, offset: 0 }}
              className="col-two"
            >
              <form
                action={`https://formspree.io/${industryEmail}`}
                method="POST"
              >
                <Fade>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formname">
                      <Form.Label>NAME</Form.Label>
                      <Form.Control
                        name="name"
                        isInvalid={!validName}
                        type="text"
                        placeholder="Please enter your name"
                        value={name}
                        onChange={e => this.setState({
                          name: e.target.value,
                          validName: e.target.value.length > 0,
                        })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a name
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="email">
                      <Form.Label>WORK EMAIL ADDRESS</Form.Label>
                      <Form.Control
                        name="email"
                        isInvalid={!validEmail}
                        type="text"
                        placeholder="Please enter your email address"
                        value={email}
                        onChange={e => this.setState({
                          email: e.target.value,
                          validEmail: this.isEmailValid(e.target.value),
                        })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email address
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="phone">
                      <Form.Label>PHONE NUMBER</Form.Label>
                      <Form.Control
                        name="phone number"
                        isInvalid={!validPhone}
                        type="text"
                        placeholder="Please enter your phone number"
                        value={phone}
                        onChange={e => this.setState({
                          phone: e.target.value,
                          validPhone: e.target.value.length > 0,
                        })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a phone number
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row id="industry-row">
                    <Col>
                      <Form.Label className="industry-label">
                        INDUSTRY
                      </Form.Label>
                      <div>
                        <div className="radio-container ">
                          <Form.Label className="container">
                            Drinks
                            <input
                              type="radio"
                              name="industry"
                              value="distilleries"
                              onClick={() => {
                                this.setState({
                                  industryEmail: distilleryEmail,
                                });
                                this.setState({ validRadio: true });
                              }}
                            />
                            <span className="checkmark" />
                          </Form.Label>
                        </div>
                        <div className="radio-container ">
                          <Form.Label className="container">
                            Food
                            <input
                              type="radio"
                              name="industry"
                              value="food"
                              onClick={() => {
                                this.setState({
                                  industryEmail: foodOtherEmail,
                                });
                                this.setState({ validRadio: true });
                              }}
                            />
                            <span className="checkmark" />
                          </Form.Label>
                        </div>
                        <div className="radio-container ">
                          <Form.Label className="container">
                            Other
                            <input
                              type="radio"
                              name="industry"
                              value="other"
                              onClick={() => {
                                this.setState({
                                  industryEmail: foodOtherEmail,
                                });
                                this.setState({ validRadio: true });
                              }}
                            />
                            <span className="checkmark" />
                          </Form.Label>
                        </div>
                      </div>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>YOUR MESSAGE</Form.Label>
                      <Form.Control
                        name="message"
                        isInvalid={!validMessage}
                        as="textarea"
                        rows="5"
                        placeholder="Please enter your message"
                        value={message}
                        onChange={e => this.setState({
                          message: e.target.value,
                          validMessage: e.target.value.length > 0,
                        })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a message
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </Fade>
                <Fade delay={200}>
                  <Button
                    className="submit-button button-hover"
                    variant="primary"
                    type="submit"
                    disabled={this.isFormValid()}
                    data-test="form-submit"
                  >
                    Submit
                  </Button>
                </Fade>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Contact;
