import React, { Component } from 'react';
import './Company.css';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Tab, Nav, Card,
} from 'react-bootstrap';
import Clients from '../common/Clients';
import arrowRight from '../../img/icons/arrow-right-icon.svg';
import forbes from '../../img/press/forbes-logo.png';
import scotchWhisky from '../../img/press/scotch-whisky.png';
import times from '../../img/press/the-times-logo.png';

export default class Company extends Component {
  render() {
    return (
      <div className="company">
        <div className="company-overview-bg">
          <Row className="company-overview">
            <Col md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 9 }}>
              <Fade>
                <h1>
                  Arc-net is dedicated to providing transparency and security to supply chains,
                  bringing deeper consumer engagement and data led business intelligence to
                  organisations.
                </h1>
                <h3>
                  We pride ourselves on the team and culture we’ve developed here at arc-net. We
                  believe that success comes from collaboration, both here in the office, and with
                  our customers. What we are doing is about more than profit and business efficiency,
                  it’s also about informing consumers and protecting the brands they purchase from.
                </h3>
              </Fade>
            </Col>
            <Col md={{ span: 12 }} lg={{ span: 4 }} xl={{ span: 3 }}>
              <Fade>
                <div className="join-link">
                  <div
                    className="join-us arrow-link"
                    role="button"
                    tabIndex={0}
                    onKeyDown={this.handleKeyDown}
                    onClick={() => {
                      this.careersRef.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    }}
                  >
                    <span>Join our team</span>
                  </div>
                </div>
              </Fade>
            </Col>
          </Row>
        </div>
        <div className="company-bottom-bg">
          <div className="company-bottom-body">
            <Row className="section-wrapper">
              <Col md={{ span: 12 }}>
                <Fade bottom>
                  <h2 className="section-title">Our leadership</h2>
                  <p className="header-subtitle-text">Meet our team</p>
                </Fade>
              </Col>
            </Row>
            <Row className="leader-one">
              <Col md={{ order: 1, span: 6 }}>
                <Fade>
                  <div className="shadow-image-left richard leader-img" />
                </Fade>
              </Col>
              <Col md={{ order: 2, span: 6 }}>
                <Fade>
                  <h4 className="name">Dr Richard Steeves</h4>
                  <h5 className="job-title">CEO</h5>
                  <p className="bio">
                    Richard is an entrepreneur who founded the outsourcing business Synergy Health
                    plc. With initial seed capital of £1 million and subsequent funding of £220
                    million, he created a global leader with 6000 employees across 16 countries,
                    including China and the US. Synergy was sold for £1.4 billion.
                  </p>
                  <p className="bio">
                    Richard was awarded EY&apos;s UK Entrepreneur of the year 2015
                  </p>
                </Fade>
                <Fade bottom delay={200}>
                  <div className="separator" />
                </Fade>
              </Col>
            </Row>
            <Row className="leader-two">
              <Col
                xs={{ span: 12, order: 2 }}
                sm={{ span: 12, order: 2 }}
                md={{ span: 7, order: 1 }}
                lg={{ span: 7, order: 1 }}
              >
                <Fade>
                  <h4 className="name">Drew Lyall</h4>
                  <h5 className="job-title">General Manager</h5>
                  <p className="bio">
                    Drew is a software engineer by trade and has over 12 years of experience
                    developing a wide range of web-applications. He got his first taste of
                    technology startups in 2012 and found the culture and challenge to be to his
                    liking so has sought out interesting opportunities in that dynamic world since
                    then.
                  </p>
                  <p className="bio">
                    Drew joined arc-net as its first lead engineer and shortly found himself as the
                    general manager for the Scottish office, taking charge of the drinks industry
                    for the company.
                  </p>
                </Fade>
                <Fade bottom delay={200}>
                  <div className="separator" />
                </Fade>
              </Col>
              <Col
                xs={{ span: 12, order: 1 }}
                sm={{ span: 12, order: 1 }}
                md={{ span: 5, order: 2 }}
                lg={{ span: 5, order: 2 }}
              >
                <Fade>
                  <div className="shadow-image-right drew leader-img" />
                </Fade>
              </Col>
            </Row>
          </div>
          <div className="our-team">
            <Fade delay={100}>
              <div className="featured img-1" />
            </Fade>
            <Fade delay={500}>
              <div className="featured img-2" />
            </Fade>
            <Fade delay={300}>
              <div className="featured img-3" />
            </Fade>
            <Fade delay={300}>
              <div className="featured img-4" />
            </Fade>
            <Fade delay={900}>
              <div className="featured img-5" />
            </Fade>
            <Fade delay={700}>
              <div className="featured img-6" />
            </Fade>
          </div>
        </div>
        <div className="dark-bg">
          <Row className="our-values-body">
            <Fade delay={300} duration={1500}>
              <Col className="our-values" md={{ span: 12 }}>
                <h1 className="header-title-text">Our values</h1>
                <div className="header-subtitle-darker-text">
                  <p className="header-subtitle-text">
                    Our values guide the way we work and the way we grow as a company.
                  </p>
                </div>
              </Col>
              <Col className="our-values" md={{ span: 12 }}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Nav>
                      <Col md={{ span: 12 }} lg={{ span: 2 }} className="our-values-col">
                        <Nav.Item>
                          <Nav.Link eventKey="first">Integrity</Nav.Link>
                        </Nav.Item>
                      </Col>
                      <Col md={{ span: 12 }} lg={{ span: 2 }} className="our-values-col">
                        <Nav.Item>
                          <Nav.Link eventKey="second">Community</Nav.Link>
                        </Nav.Item>
                      </Col>
                      <Col md={{ span: 12 }} lg={{ span: 2 }} className="our-values-col">
                        <Nav.Item>
                          <Nav.Link eventKey="third">Curiosity</Nav.Link>
                        </Nav.Item>
                      </Col>
                      <Col md={{ span: 12 }} lg={{ span: 2 }} className="our-values-col">
                        <Nav.Item>
                          <Nav.Link eventKey="fourth">Tenacity</Nav.Link>
                        </Nav.Item>
                      </Col>
                      <Col md={{ span: 12 }} lg="auto" className="our-values-col">
                        <Nav.Item>
                          <Nav.Link eventKey="fifth">Excellence</Nav.Link>
                        </Nav.Item>
                      </Col>
                    </Nav>
                  </Row>
                  <Row className="tab-content-row">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        We are
                        <b> transparent </b>
                        and
                        <b> open</b>
                        . We offer
                        <b> trust </b>
                        to our partners and assume it from them.
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        We are our
                        <b> team </b>
                        - a small company must believe in its
                        <b> people </b>
                        and give them the opportunity to help us all succeed.
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        We always seek to
                        <b> learn </b>
                        and use what we&apos;ve learned to
                        <b> improve. </b>
                        Creativity and the
                        <b> user experience </b>
                        are at the forefront of our offering.
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        We are
                        <b> persistent </b>
                        and
                        <b> determined. </b>
                        We strive to compete at the highest levels and are committed to producing
                        precise, fit for purpose solutions.
                      </Tab.Pane>
                      <Tab.Pane eventKey="fifth">
                        Every one of our values contributes towards the
                        <b> excellence </b>
                        in our products. Attention to detail and close
                        <b> collaboration </b>
                        allows us to deliver the very best to our customers.
                      </Tab.Pane>
                    </Tab.Content>
                  </Row>
                </Tab.Container>
              </Col>
            </Fade>
          </Row>
        </div>

        <div
          ref={(ref) => {
            this.careersRef = ref;
          }}
        >
          <Row className="careers-row">
            <Fade>
              <h2 className="section-title">Careers</h2>
            </Fade>
            <div className="careers-wrapper">
              <Fade>
                <p>
                  Check out our open roles below or if you don&apos;t see anything that&apos;s quite
                  right for you, email us your CV to &nbsp;
                  <a className="mail-to" href="mailto: careers@arc-net.io?subject=Careers">
                    info@arc-net.io
                  </a>
                  &nbsp; anyway. For the right person we&apos;ll get in touch if something comes up.
                </p>
                <p>
                  At arc-net we are our people and we value talent and commitment. We are carving
                  out a new space in some old industries and that requires both creativity and
                  resilience. We love what we do here and we think you will too!
                </p>
              </Fade>

              <div className="roles-section">
                <Fade>
                  {/* <Link to="/vacancy/business-analyst" className="careers-link">
                    <span className="arrow-link">Business Analyst</span>
                  </Link>
                  <Link to="/vacancy/business-development-manager" className="careers-link">
                    <span className="arrow-link">Business Development Manager</span>
                  </Link> */}
                  <Link to="/vacancy/software-engineer" className="careers-link">
                    <span className="arrow-link">Software Engineer</span>
                  </Link>
                  {/* <Link to="/vacancy/junior-ui-designer" className="careers-link">
                    <span className="arrow-link">Junior UI Designer</span>
                  </Link>
                  <Link to="/vacancy/infrastructure-engineer" className="careers-link">
                    <span className="arrow-link">DevOps/Infrastructure/SRE engineer</span>
                  </Link>
                  <Link to="/vacancy/junior-software-engineer" className="careers-link">
                    <span className="arrow-link">Junior Software Engineer</span>
                  </Link> */}
                  <Link to="/vacancy/junior-qa-engineer" className="careers-link">
                    <span className="arrow-link">Junior QA Engineer</span>
                  </Link>
                </Fade>
              </div>
            </div>
          </Row>
        </div>
        <div className="dark-bg">
          <Container className="mentions-section">
            <Row>
              <Col className="heading-wrapper" md={{ span: 12 }}>
                <h2 className="press-heading">They talk about us:</h2>
              </Col>
            </Row>
            <Row className="mentions-card-layout">
              <Col className="mentions" md={{ span: 12 }} lg={{ span: 4 }}>
                <Fade delay={150}>
                  <Card.Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.thetimes.co.uk/article/blockchain-tracks-food-all-the-way-from-farm-to-table-dz9s2zv32"
                  >
                    <Card className="mentions-card">
                      <Card.Header>
                        <img src={times} alt="Arc-net featured in The Times" />
                      </Card.Header>
                      <Card.Body>
                        <Card.Text className="mentions-intro">
                          Arc-net uses the technology that powers bitcoin and other cryptocurrencies
                          to build a trustworthy record of an animal’s life all the way from farm to
                          supermarket shelf.
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer>
                        <img src={arrowRight} alt="See more" />
                      </Card.Footer>
                    </Card>
                  </Card.Link>
                </Fade>
              </Col>
              <Col className="mentions" md={{ span: 12 }} lg={{ span: 4 }}>
                <Fade delay={300}>
                  <Card.Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.forbes.com/sites/felipeschrieberg/2017/09/29/can-blockchain-technology-stop-whisky-counterfeiting/#557c05a4791a"
                  >
                    <Card className="mentions-card">
                      <Card.Header>
                        <img src={forbes} alt="Arc-net featured by the Forbes" />
                      </Card.Header>
                      <Card.Body className="mentions-card-inner-layout">
                        <Card.Text className="mentions-intro">
                          This campaign represents a partnership with Edinburgh based Arc-net, a
                          company specializing in authenticating supply chain information for
                          consumers. (...) the introduction of blockchain could potentially be a
                          game changer as both industry and consumer win.
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer>
                        <img src={arrowRight} alt="See more" />
                      </Card.Footer>
                    </Card>
                  </Card.Link>
                </Fade>
              </Col>
              <Col className="mentions" md={{ span: 12 }} lg={{ span: 4 }}>
                <Fade delay={450}>
                  <Card.Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://scotchwhisky.com/magazine/latest-news/25066/ailsa-bay-traces-whisky-with-blockchain-tech/"
                  >
                    <Card className="mentions-card">
                      <Card.Header>
                        <img
                          src={scotchWhisky}
                          alt="Ailsa Bay powered by arc-net's blockchain based technology"
                        />
                      </Card.Header>
                      <Card.Body className="mentions-card-inner-layout">
                        <Card.Text className="mentions-intro">
                          The distillery, which is owned by William Grant & Sons, partnered with
                          specialist blockchain technology company arc-net to allow customers to
                          track the expression from ‘source to store’.
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer>
                        <img src={arrowRight} alt="See more" />
                      </Card.Footer>
                    </Card>
                  </Card.Link>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>
        <Clients />
        <div className="media-wrapper">
          <Fade bottom>
            <h2 className="media-heading"> Media Information</h2>
          </Fade>
          <Fade>
            <span>
              Writing about arc-net? If you have a media enquiry relating to our work, please
              contact us at &nbsp;
              <a className="mail-to" href="mailto: info@arc-net.io?subject=Press inquiry">
                info@arc-net.io
              </a>
            </span>
          </Fade>
        </div>
      </div>
    );
  }
}
