import React from 'react';
import './DemoFooter.css';
import { Container } from 'react-bootstrap';
import IconRow from './IconRow';

export default function DemoFooter() {
  return (
    <div className="demo-footer">
      <Container className="demo-footer-bg">
        <IconRow />
      </Container>
    </div>
  );
}
