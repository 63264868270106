import * as types from './action-types';


export const initialState = {
  clicked: false,
  activeTab: '#distilledid',
  loading: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.TOGGLECLICK:
      return Object.assign({}, state, {
        clicked: action.clickState,
      });
    case types.SETACTIVETAB:
      return Object.assign({}, state, {
        activeTab: action.activeTab,
      });
    case types.SETLOADING:
      return Object.assign({}, state, {
        loading: action.loadingState,
      });
    default:
      return state;
  }
}

export default reducer;
