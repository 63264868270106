import React from 'react';
import NewsTemplate from '../components/common/NewsTemplate';
import newhome1 from '../img/blog/newhome1.jpg';
import newhome2 from '../img/blog/newhome2.jpg';
import newhome3 from '../img/blog/newhome3.jpg';
import officeimg from '../img/blog/office.jpg';

export default function newHome(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Arc-net's new home"
      image={officeimg}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        Arc-net Scotland started life in what was, at the time, the Entrepreneurial Spark program
        provided by RBS.
      </p>
      <p>
        The “E-Spark” programme was hugely valuable to arc-net, not only providing us with a working
        environment at the RBS site in Gogarburn, Edinburgh, while we got the engineering team on
        its feet but also helping us find our market and craft our message. E-Spark isn’t designed
        for long term however and after a year or so arc-net was ready to expand and move on.
        <p />
      </p>
      <p>
        We still weren’t a big team and we definitely weren’t quite ready to take on a large scale
        office, but similarly, we wanted more freedom and autonomy than would’ve been available to
        us in a serviced space. So we found a home at Giles Street in Leith (conveniently just a few
        moments walk away from the Scotch Malt Whisky Society’s vaults location).
      </p>
      <img src={newhome1} alt="off outside" />
      <p>
        Leith has a hugely creative energy and an incredibly diverse heart. Nowhere else in
        Edinburgh are greasy spoon cafes sitting right next to fancy bakeries and Michelin star
        restaurants! Giles Street served us well for about eighteen months (with the exception of
        winter time when the office was unbelievably cold!) but eventually, as the team built up and
        we started to need something more formal, we had to look elsewhere once again.
      </p>
      <img src={newhome2} alt="office inside" />
      <p>Forward to January 2019 and welcome to arc-net’s new home!</p>
      <p>
        Nestled in the heart of Edinburgh’s financial business area - what is locally known as the
        “Exchange District” we have found our new home in No. Two Lochrin Square. A beautiful big
        space that should serve our needs for a number of years to come, complete with kitchen and
        meeting facilities - the arc-net family will be happy here for a long time.
      </p>
      <img src={newhome3} alt="inside new office" />
      <p>What a fantastic way to start the new year!</p>
    </NewsTemplate>
  );
}
