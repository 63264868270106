import React, { Component } from 'react';
import './Privacy.css';
import Fade from 'react-reveal/Fade';
import { Row } from 'react-bootstrap';

export default class Privacy extends Component {
  render() {
    return (
      <div className="privacy">
        <div className="privacy-overview-bg">
          <Row className="privacy-overview">
            <Fade>
              <h1>Legal Notice & Privacy Policy</h1>
              <h3>Please read the following carefully to understand our views and practices.</h3>
            </Fade>
          </Row>
        </div>
        <div className="policy-wrapper">
          <div className="section-wrapper">
            <Fade bottom>
              <h1>Controllers of Information</h1>
            </Fade>
            <Fade>
              <p>
                Any personal information provided to or gathered by our website is controlled by
                Advanced Research Cryptography Ltd, No. 2 Lochrin Square, 96 Fountainbridge,
                Edinburgh, EH3 9QA. If you have any queries about this document or how we use your
                data, please contact:
              </p>
              <p>P: +44 (0) 131 2255 558</p>
              <p>
                E:
                <a href="mailto:info@arc-net.io"> info@arc-net.io</a>
              </p>
              <p>
                We aim to process information about you fairly and in a transparent manner and the
                aim of this document is to provide you with sufficient information for you to be
                able to understand what we are doing with your data. If you are unsure how we are
                handling information about you or you think we could improve our privacy information
                please let us know.
              </p>
            </Fade>
          </div>
          <div className="section-wrapper">
            <Fade bottom>
              <h1>What information do we collect? How do we use it?</h1>
            </Fade>
            <Fade>
              <p>
                When you use our website and/or register with us we may collect, derive, combine,
                access, or otherwise process your personal information for the following purposes:
              </p>
              <ul>
                <li>The monitoring of customer traffic patterns and website usage.</li>
                <li>
                  Notifying you of arc-net news and information, which we think, may be of interest
                  to you including by email.
                </li>
                <li>Offering, administering and providing products and services.</li>
                <li>
                  Providing you with a more personalised user experience when using our application,
                  website or any other services we might provide to you by analysing, segmenting and
                  profiling our database and allowing us to target you with information we think you
                  might be most interested in including products and services.
                </li>
                <li>Sending you surveys for quality management purposes.</li>
              </ul>
            </Fade>
          </div>
          <div className="section-wrapper">
            <Fade bottom>
              <h1>Legal basis for processing your data</h1>
            </Fade>
            <Fade>
              <p>
                We undertake these activities in the pursuit of our legitimate interests as a
                commercial organisation including the marketing and promotion of our brand and
                services and the promotion of the arc-net brand and to fulfil any contractual
                obligations we may have.
              </p>
            </Fade>
          </div>
          <div className="section-wrapper">
            <Fade bottom>
              <h1>Retention</h1>
            </Fade>
            <Fade>
              <p>
                We collect personal information for specific purposes and on specific legal bases
                and endeavour to collect only the personal information required for those purposes.
                Once this purpose has been fulfilled and the personal information is no longer
                required, we will anonymise your data by removing any identifiable information or
                erase it.
              </p>
            </Fade>
          </div>
          <div className="section-wrapper">
            <Fade bottom>
              <h1>Who has access to your data</h1>
            </Fade>
            <Fade>
              <p>
                Access to your personal data is restricted at all times. Only our employees will be
                able to access your data. Their access and activities are restricted to the purposes
                outlined above.
              </p>
            </Fade>
          </div>
          <div className="section-wrapper">
            <Fade bottom>
              <h1>Your email address is safe with us</h1>
            </Fade>
            <Fade>
              <ul>
                <li>We never sell or share your email addresses with our companies.</li>
                <li>You can unsubscribe at any time.</li>
                <li>Offering, administering and providing products and services.</li>
                <li>
                  We require that each email message sent out from us includes an easy way for
                  subscribers to remove themselves via an unsubscribe link.
                </li>
                <li>
                  If you receive a newsletter or email and decide you don’t like it, simply click
                  the unsubscribe link at the bottom of the email.
                </li>
                <li>
                  If you feel you’ve been sent unsolicited email and would like to register a
                  complaint, please email us at info@arc-net.io
                </li>
              </ul>
            </Fade>
          </div>
          <div className="section-wrapper">
            <Fade bottom>
              <h1>Your rights</h1>
            </Fade>
            <Fade>
              <p>
                You have the right to request at reasonable intervals access to the personal
                information we have collected about you. Please refer any access request to
                info@arc-net.io. When requesting access to your personal data provide as much detail
                as possible about what personal data you would like access to and include in the
                subject line that it regards an access request. For complying with your access
                request, we may ask you to provide us with additional information as well as charge
                a fee up to the maximum allowed under the data protection law applicable to you. If
                you so choose you may request us to rectify, erase or block the processing of your
                personal data if that personal data is no longer accurate, up-to-date or when the
                further processing of that personal data would be in violation of applicable data
                protection legislation.
              </p>
              <p>
                You have a right to receive any personal data that you have provided to us in order
                to transfer it onto another data controller where the processing is based on consent
                and is carried out by automated means called a data portability request and you have
                the right to object to our processing of your personal data where the basis of the
                processing is our legitimate interests including but not limited to direct marketing
                and profiling. Please see above to determine which processing activities are carried
                out on the basis of our legitimate interests.
              </p>
              <p>
                You have a right to information about how we are processing information about you
                and we have an obligation to processes only a minimum of data for specific, explicit
                and legitimate purposes in a fair, lawful and transparent manner. This privacy
                notice fulfils our obligations to provide you with information, but you should
                contact us directly if you have any questions or concerns. You have a right to lodge
                a complaint about any aspect of how we are handling your data with Information
                Commissioners Office (ICO) who can be contacted here.
              </p>
            </Fade>
          </div>
          <div className="section-wrapper">
            <Fade bottom>
              <h1>Any questions regarding your privacy?</h1>
            </Fade>
            <Fade>
              <p>
                We are happy to discuss privacy and data protection with you. Please contact
                info@arc-net.io or call: +44 (0)131 2255 558 if you have any questions.
              </p>
            </Fade>
          </div>
          <div className="section-wrapper">
            <Fade bottom>
              <h1>Security</h1>
            </Fade>
            <Fade>
              <p>
                We endeavour to take all reasonable steps to protect your personal data including
                the use of encryption technology but cannot guarantee the security of any data you
                disclose online. You accept the inherent security implications of sending
                information over the Internet and will not hold us responsible for any breach of
                security unless we have been negligent.
              </p>
            </Fade>
          </div>
          <div className="section-wrapper">
            <Fade bottom>
              <h1>Applicable Data Protection Law</h1>
            </Fade>
            <Fade>
              <p>
                This privacy notice has been based on the General Data Protection Regulation (GDPR).
                If you are subject to more stringent data protection legislation please refrain from
                using this website or any of our other services.
              </p>
            </Fade>
          </div>
          <div className="section-wrapper">
            <Fade bottom>
              <h1>Changes to this notice</h1>
            </Fade>
            <Fade>
              <p>
                We might periodically change this privacy notice. If we decide to change our privacy
                notice, we will post those changes on this page. We therefore recommend you re-visit
                this page periodically to check for changes.
              </p>
            </Fade>
          </div>
        </div>
      </div>
    );
  }
}
