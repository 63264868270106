import React from 'react';
import './YourJourney.css';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

export default function YourJourney() {
  return (
    <div className="your-journey">
      <Container>
        <Row>
          <Fade>
            <Col>
              <div className="horizontal-journey">
                <h4>Get started</h4>
                <h3>Your journey with arc-net</h3>
                <div className="section-one">
                  <div className="content-column">
                    <div className="number">1</div>
                    <div className="line" />
                    <h4>Get in touch!</h4>
                    <p>
                      Arc-net will chat with you about what you can expect to get from our
                      platform, how it works, and what the blockchain is for.
                      <Link to="/contact">
                        &nbsp;Talk to us
                      </Link>
                    </p>
                  </div>
                  <div className="content-column">
                    <div className="number">2</div>
                    <div className="line" />
                    <h4>Kick-off!</h4>
                    <p>
                      We’ll discuss the processes you want to record and how best to
                      integrate with your existing processes.
                    </p>
                  </div>
                  <div className="content-column">
                    <div className="number">3</div>
                    <h4>Consumer Interaction</h4>
                    <p>
                      We’ll work with you to design the perfect Storybook for your budget. This
                      is what connects your customers to your brand so it should tell your story
                      in the best way possible.
                  </p>
                  </div>
                </div>

                <div className="section-two">
                  <div className="content-column">
                    <div className="number">4</div>
                    <div className="line" />
                    <h4>Reporting</h4>
                    <p>
                      We’ll discuss your output and reporting requirements. Getting data into
                      the platform is only halfway there, your value comes from how you get
                      it out again.
                  </p>
                  </div>
                  <div className="content-column">
                    <div className="number">5</div>
                    <div className="line" />
                    <h4>Business Intelligence</h4>
                    <p>
                      Over time we’ll help you identify patterns in the data being collected.
                      Both internally and from your customers.
                  </p>
                  </div>
                  <div className="content-column">
                    <div className="number">6</div>
                    <h4>Evolution</h4>
                    <p>
                      Arc-net works closely with our customers to build on their requirements
                      over time. We will evolve your Harvest ID platform with you, adding more
                      products and enriching your storybooks.
                  </p>
                  </div>
                </div>
              </div>
            </Col>
          </Fade>
        </Row>
      </Container>
    </div>
  );
}
