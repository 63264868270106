import React from 'react';
import { Link } from 'react-router-dom';
import NewsTemplate from '../components/common/NewsTemplate';

export default function startupAward(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Arc-net win Startup of the Year at the 2017 Digital DNA Awards"
      image={require('../img/blog/startup.png')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        All of us here at arc-net were humbled to take home the award for Startup of the Year at the
        2017 Digital DNA Awards!
      </p>
      <p>
        It was a tremendous honor to be recognised by the judges amongst what truly was esteemed
        company and we had a great time at the event. Many thanks to Digital DNA for organising such
        an impressive night and to The Open University who sponsored the Startup of the Year
        Catagory.
      </p>
      <p>We’re already looking forward to next year!</p>
      <p>
        Want to know more about protecting your business from risk?
      </p>
      <Link to="/contact">Get in touch with arc-net today</Link>
    </NewsTemplate>
  );
}
