import React, { useState } from 'react';
import './Demo.css';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import {
  Row, Col, Button, Form, Container,
} from 'react-bootstrap';
import DemoFooter from '../common/DemoFooter';

export default function Demo() {
  const [name, setName] = useState('');
  const [validName, setValidName] = useState(true);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [industryEmail, setIndustryEmail] = useState('');
  const [validRadio, setValidRadio] = useState('');
  const [message, setMessage] = useState('');
  const [validMessage, setValidMessage] = useState(true);
  const DELAY1000 = 300;

  const isEmailValid = function isEmailValid(emailString) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(emailString).toLowerCase());
  };

  const isFormValid = function isFormValid() {
    const emptyForm = name === '' || email === '' || phoneNumber === '' || message === '';
    const valid = validName && validEmail && validPhoneNumber && validRadio;
    return !(valid && !emptyForm);
  };

  const distilleryEmail = 'info@distilledid.com';
  const foodOtherEmail = 'info@arc-net.io';

  return (
    <div className="request-demo">
      <Container fluid className="request-container">
        <Row className="request-demo-row">
          <Col xs={12} lg={6} className="col-one-bg left-col">
            <div className="col-one">
              <Fade delay={DELAY1000}>
                <div className="back-block">
                  <Link to="'/'" className="arrow-left" />
                </div>
                <p className="demo-title block">See how our solution can work with your products</p>
              </Fade>

              <form action={`https://formspree.io/${industryEmail}`} method="POST">
                <Fade delay={DELAY1000}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formname">
                      <Form.Label>NAME</Form.Label>
                      <Form.Control
                        test="form-name"
                        name="name"
                        isInvalid={!validName}
                        type="text"
                        placeholder="Please enter your name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setValidName(e.target.value.length > 0);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a name
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <Form.Label className="industry-label">INDUSTRY</Form.Label>
                      <div>
                        <div className="radio-container ">
                          <Form.Label className="container">
                            Drink
                            <input
                              type="radio"
                              name="industry"
                              value="distilleries"
                              onClick={() => {
                                setIndustryEmail(distilleryEmail);
                                setValidRadio(true);
                              }}
                            />
                            <span className="checkmark" />
                          </Form.Label>
                        </div>
                        <div className="radio-container ">
                          <Form.Label className="container">
                            Food
                            <input
                              type="radio"
                              name="industry"
                              value="food"
                              onClick={() => {
                                setIndustryEmail(foodOtherEmail);
                                setValidRadio(true);
                              }}
                            />
                            <span className="checkmark" />
                          </Form.Label>
                        </div>
                        <div className="radio-container ">
                          <Form.Label className="container">
                            Other
                            <input
                              type="radio"
                              name="industry"
                              value="other"
                              onClick={() => {
                                setIndustryEmail(foodOtherEmail);
                                setValidRadio(true);
                              }}
                            />
                            <span className="checkmark" />
                          </Form.Label>
                        </div>
                      </div>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="email">
                      <Form.Label>WORK EMAIL ADDRESS</Form.Label>
                      <Form.Control
                        test="form-email"
                        name="email"
                        isInvalid={!validEmail}
                        type="text"
                        placeholder="Please enter your email address"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setValidEmail(isEmailValid(e.target.value));
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email address
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="phone">
                      <Form.Label>PHONE NUMBER</Form.Label>
                      <Form.Control
                        test="form-phone"
                        name="phone number"
                        isInvalid={!validPhoneNumber}
                        type="text"
                        placeholder="Please enter your phone number"
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                          setValidPhoneNumber(e.target.value.length > 0);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid phone number
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="message">
                      <Form.Label>YOUR MESSAGE</Form.Label>
                      <Form.Control
                        test="form-message"
                        name="message"
                        isInvalid={!validMessage}
                        as="textarea"
                        rows="5"
                        placeholder="Please enter your message"
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                          setValidMessage(e.target.value.length > 0);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a message
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </Fade>
                <Fade delay={DELAY1000}>
                  <Button
                    test="form-submit"
                    className="submit-button button-hover"
                    type="submit"
                    disabled={isFormValid()}
                  >
                    SUBMIT
                  </Button>
                </Fade>
              </form>
            </div>
          </Col>
          <Col xs={12} lg={6} className="col-two-bg right-col">
            <div className="col-two">
              <Fade delay={DELAY1000}>
                <h2> Let us help you transform your organisation. </h2>
              </Fade>
              <ul>
                <Fade delay={DELAY1000}>
                  <li>
                    <h3>Model complex supply chains</h3>
                    <p>
                      Our core platform can quickly and elegantly model your supply chain and
                      business processes providing the foundation to track any type of product on
                      even very complex supply chains.
                    </p>
                  </li>
                  <li>
                    <h3>Leverage emerging blockchain technology</h3>
                    <p>
                      See why there is hype around the use of blockchain in value systems and how we
                      can share the trust it brings with your customers by driving advanced
                      engagement.
                    </p>
                  </li>
                </Fade>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <DemoFooter />
    </div>
  );
}
