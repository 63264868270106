import React from 'react';
import NewsTemplate from '../components/common/NewsTemplate';

export default function foodFraud(props) {
  const { locations } = props;

  return (
    <NewsTemplate
      heading="We're Presenting at Food Fraud - A New Food Conference on 3rd May 2017 in Manchester"
      image={require('../img/blog/food-fraud-sq.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        Arc-net CEO Kieran Kelly will take the stage at the 2017 Food Fraud Conference in Manchester
        to discuss “Hacking The Supply Chain” - our unique approach to combating fraud in our food!
      </p>
      <p>
        The conference run by New Food, will be chaired by Professor Chris Elliot, Founder of the
        Institute for Global Food Safety and Pro-Vice Chancellor of Queen’s University Belfast. The
        day will feature a number of events, bringing together a collection of industry
        professionals and food safety experts to discuss all of the biggest issues concerning food,
        fraud and technology.
      </p>
      <p>
        As well as case studies, panel discussions, spotlight focuses and presentations, the
        programme will also include Q&A sessions in order to enable attendees to gain a full insight
        into the issues concerning food fraud.
      </p>
      <p>
        Kieran will be speaking at 10:10am, and as part of his presentation will touch on our use of
        blockchain, digital DNA and other innovative technologies. If you’re attending the
        conference pop along to listen or come say hello.
      </p>
      <p>
        For more information on The Food Fraud Conference visit:
      </p>
      <a href="http://www.newfoodfraud.com/" target="_blank" rel="noopener noreferrer">
          www.newfoodfraud.com
      </a>
      <p>
        or to find out more about the topics we will be presenting on drop us a line at
      </p>
      <a href="mailto:info@arc-net.io"> info@arc-net.io</a>
      <p>We look forward to seeing you all there!</p>
    </NewsTemplate>
  );
}
