import React from 'react';
import '../Vacancy.css';
import Fade from 'react-reveal/Fade';
import VacancyTemplate from '../../common/VacancyTemplate';
import separator from '../../../img/icons/paragraph-separator.svg';

const Engineer = () => {
  const jobTitle = 'Software Engineer';
  const jobDescription = `Arc-net is looking for a talented Software Engineer to join our growing
  development team in Edinburgh.`;
  return (
    <VacancyTemplate jobTitle={jobTitle} jobDescription={jobDescription}>
      <Fade bottom>
        <h1 className="bottom-header-text">Who we are</h1>
      </Fade>
      <Fade>
        <p className="bottom-text">
          Arc-net is a tech startup based in Edinburgh, the beautiful capital in the heart of
          Scotland. At arc-net, we are dedicated to providing transparency and security to supply
          chains, bringing deeper consumer engagement and data led business intelligence to
          organisations. We’re carving out a new space in some old industries and that requires both
          creativity and resilience. Working in anything from aviation to whisky, we love what we do
          here and we think you will too!
        </p>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">The role</h1>
      </Fade>
      <Fade>
        <p className="bottom-text">
          We are currently looking to expand our development team with individuals who can
          demonstrate a proven ability to deliver quality software as part of an agile,
          multi-disciplinary team. You will be working with a microservice based architecture
          supported by docker and running in the cloud, utilising blockchain technology to ensure
          data integrity for customers. As part of a fast moving start-up, you will have the
          opportunity to work in many different areas of development and will be expected to share
          your expertise with other members of the team during design discussions and code reviews.
        </p>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">Core responsibilities</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>
            Contributing to the future design and implementation of the arc-net platform as the
            requirements and technical landscape changes
          </li>
          <li>
            Taking ownership of tasks; leading the design and implementation of features whilst
            working collaboratively with other developers
          </li>
          <li>
            Actively look for emerging technologies and development approaches that can benefit our
            software development process
          </li>
          <li>
            Ensure software is developed to agreed consistent standards by participating in code
            reviews and following agreed style guidelines
          </li>
          <li>Coaching and mentoring less experienced developers</li>
        </ul>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">Specific skills and experience required</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>Development of RESTful web services</li>
          <li>Experience with component based JavaScript front end web frameworks, ReactJS + Redux</li>
          <li>Experience with mobile development using React Native</li>
          <li>Comfortable working in UNIX based environments using command line tooling</li>
          <li>Experience with JavaScript testing frameworks: Jest/Mocha/Enzyme or similar</li>
          <li>Strong grasp of OO Design, patterns and principles</li>
          <li>SQL Relational databases</li>
          <li>3+ years industry experience with a related degree level qualification</li>
        </ul>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">Desirable experience</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>Ruby on Rails</li>
          <li>Node.js, specifically Express</li>
          <li>Docker</li>
          <li>Kubernetes</li>
          <li>Microservices</li>
          <li>Agile development methodologies</li>
          <li>Cloud infrastructure</li>
          <li>CI pipeline tools such as Travis and automated release processes</li>
        </ul>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">What you can expect from us</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>Salary of £35,000 - £45,000 per annum dependant on experience</li>
          <li>Great Edinburgh location.</li>
          <li>Dynamic start-up culture.</li>
          <li>Flexible working environment (including remote working where required).</li>
          <li>An open and welcoming culture with a great appetite for new technology and ideas.</li>
        </ul>
        <p className="end-text">If you think this is for you, we&apos;d love to hear from you.</p>
      </Fade>
    </VacancyTemplate>
  );
};

export default Engineer;
