import React from 'react';
import { Link } from 'react-router-dom';
import ResponsiveEmbed from 'react-responsive-embed';
import NewsTemplate from '../components/common/NewsTemplate';

export default function blockchainQuestions(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="VIDEO - Blockchain Answering the Questions the Future is Asking."
      image={require('../img/blog/blockchainquestion1.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        Our global supply chains are no longer fit for purpose. By PwC’s own estimates, food fraud
        is a $40 Billion a year global issue.
      </p>
      <p>
        It is no surprise then that consumers are seeking transparency and trust across todays
        modern supply chains. According to a recent Response Media study, 62% of consumers will
        often or always switch to a transparent product and are willing to pay up to 99% more for a
        transparent product.
      </p>
      <p>
        So what can be done to solve this problem? For arc-net, the solution lies in the blockchain.
        The blockchain is the technology that underpins crypto-currencies such as bitcoin and was
        developed as a decentralized ledger which records transactions and stores this information
        on a global network in a manner which prevents it being changed at a future point. While
        initially adopted for its financial implications, the blockchains’ decentralized system has
        huge potential for the traceability of supply chains.
      </p>
      <p>
        Watch the video below to find out more about how blockchain technology can be used to
        protect our global food supply or visit
        <Link to="/pwc"> www.arc-net.io/news/pwc </Link>
        to find out more about arc-nets work with
        PwC in the food supply chain.
      </p>

      <ResponsiveEmbed src="https://www.youtube.com/embed/Le5ljXDph8I" allowFullScreen />
      <br />
      <p>
        Source:
        <a
          href="http://www.responsemedia.com/2017-transparency-study/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.responsemedia.com/2017-transparency-study/
        </a>
      </p>
    </NewsTemplate>
  );
}
