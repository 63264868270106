import React, { Component } from 'react';
import {
  Tab, Container, Row, Col, Nav,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import TabFood from './TabFood';
import TabDistilleries from './TabDistilleries';
import TabProducers from './TabProducers';
import './Solutions.css';
import { setActiveTab } from '../../store/actions';

class Solutions extends Component {
  render() {
    const { tab, setTab } = this.props;
    return (
      <div className="solutions">
        <Fade bottom>
          <h2>We have a solution for your industry:</h2>
        </Fade>
        <Tab.Container
          id="left-tabs-example"
          activeKey={tab}
          onSelect={key => setTab(key)}
        >
          <Container>
            <Fade bottom>
              <Row className="tab-row">
                <Nav>
                  <Col lg={4} sm={12}>
                    <Nav.Item>
                      <Nav.Link eventKey="#distilledid">
                        Distilleries & Breweries
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Nav.Item>
                      <Nav.Link eventKey="#harvestid">Food & Farming</Nav.Link>
                    </Nav.Item>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Nav.Item>
                      <Nav.Link eventKey="#other">Your Business</Nav.Link>
                    </Nav.Item>
                  </Col>
                </Nav>
              </Row>
            </Fade>
          </Container>
          <Container fluid>
            <Row className="tab-content-row">
              <Col sm={12} className="no-margin">
                <Tab.Content>
                  <Tab.Pane eventKey="#distilledid">
                    <TabDistilleries />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#harvestid">
                    <TabFood />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#other">
                    <TabProducers />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Container>
        </Tab.Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tab: state.activeTab,
});

const mapDispatchToProps = dispatch => ({
  setTab: activeTab => dispatch(setActiveTab(activeTab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Solutions);
