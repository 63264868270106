import React, { Fragment } from 'react';
import Fade from 'react-reveal/Fade';
import { Row, Col, Button } from 'react-bootstrap';

const VacancyTemplate = ({ children, jobTitle, jobDescription }) => (
  <Fragment>
    <div className="vacancy">
      <Row className="vacancy-top-bg">
        <Col md={{ span: 12 }} className="vacancy-top-body max-width">
          <Fade>
            <h1 className="top-header-text">{jobTitle}</h1>
            <div className="top-text">
              {jobDescription}
            </div>

            <div className="apply-link">
              <a href="mailto:careers@arc-net.io" className="apply-link-text arrow-link">
                Apply now
              </a>
            </div>
          </Fade>
        </Col>
      </Row>
      <Row className="vacancy-bottom-bg max-width">
        <Col md={{ span: 12 }} className="vacancy-bottom-body">
          {children}
          <Fade>
            <div className="apply-button-position">
              <Button className="button apply-button button-hover" href="mailto:careers@arc-net.io">
                Apply Now
              </Button>
            </div>
          </Fade>
        </Col>
      </Row>
    </div>
  </Fragment>
);

export default VacancyTemplate;
