import React, { Component } from 'react';
import './Layout.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import NavigationMenu from './NavigationMenu';
import Footer from './Footer';
import Config from '../../constant/Config';
import { setLoading } from '../../store/actions';

class Layout extends Component {
  componentDidMount() {
    const { history, setLoad } = this.props;
    this.listener = history.listen(() => {
      setLoad(true);
      setTimeout(() => {
        setLoad(false);
      }, 1200);
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  render() {
    const { location, children } = this.props;
    return (
      <div className="layout">
        {!Config.noHeaderFooterRoutes.includes(location.pathname) && <NavigationMenu />}
        <Fade>{children}</Fade>
        {!Config.noHeaderFooterRoutes.includes(location.pathname) && <Footer />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  setLoad: loadingState => dispatch(setLoading(loadingState)),
});
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Layout),
);
