import React from 'react';
import './FeatureRow.css';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import squareIcon from '../../img/icons/square-icon.svg';

export default function FeatureRow(props) {
  const { features } = props;
  return (
    <div className="feature">
      <Container fluid>
        <Row className="feature-row">
          {features.map((item, i) => (
            <Col md={4} key={item.title}>
              <Fade delay={i * 500}>
                <img
                  className="squareIcon"
                  src={squareIcon}
                  alt=""
                />
                <h2>{item.title}</h2>
                <h3>
                  {item.content}
                </h3>
              </Fade>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
