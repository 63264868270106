import React from 'react';
import '../Vacancy.css';
import Fade from 'react-reveal/Fade';
import VacancyTemplate from '../../common/VacancyTemplate';
import separator from '../../../img/icons/paragraph-separator.svg';

const Engineer = () => {
  const jobTitle = 'DevOps/Infrastructure Engineer ';
  const jobDescription = `Arc-net is looking for a talented DevOps/Infrastructure/SRE
  engineer to join our growing development team in Edinburgh.`;
  return (
    <VacancyTemplate jobTitle={jobTitle} jobDescription={jobDescription}>
      <Fade bottom>
        <h1 className="bottom-header-text">Who we are</h1>
      </Fade>
      <Fade>
        <p className="bottom-text">
          Arc-net is a technology business that is helping to accelerate the adoption of blockchain
          and distributed ledger technology (DLT) in a diverse range of markets. Arc-net is primarily
          a SaaS and API service provider offering mainly B2B with some B2C services. Initially focusing
          on traceability and supply chain provenance but with ambitions to create a range of other
          services that fully exploit the functionality of blockchain including smart contracts and
          payment systems.
          </p>
        <p className="bottom-text">
          As a new tech start-up based in Edinburgh, the beautiful capital in the heart of Scotland,
          we are building a platform to track products as they pass through elaborate, customer supply
          chains and we need you to help us build the tools to support and visualise that data for our
          customers and their consumers.
        </p>
        <p className="bottom-text">
          Arc-net also works closely with our sister company TrustFlight and part of the role would be
          to advise on and help implement their operational environment. TrustFlight also operates a SaaS
          service but in their case, they are providing efficiencies and enhanced safety into the
          aviation industry.
        </p>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">What we need</h1>
      </Fade>
      <Fade>
        <p className="bottom-text">
          To achieve arc-net’s goal of delivering these services to our customers we need a
          passionate DevOps engineer with a proven ability to develop quality architecture
          and maintain robust services as part of an agile, multi-disciplinary team. You should
          be confident taking charge of projects and working with our developers to rapidly reach
          MVP deployments to test out new features and concepts. You should help to bring clarity
          to the company’s vision for its products and create a standard as an example to the team.
          As a member of the engineering team at arc-net you will have the opportunity to work
          with some leading-edge technology including blockchain and a microservice architecture
          packaged in Docker and running on Google Kubernetes Engine (GKE). As part of a
          fast-moving start-up, you will have the opportunity to bring in your own ideas
          and experience and guide the company on best practice.
        </p>
        <p className="bottom-text">
          TrustFlight have similar architectural requirements but are earlier in their operational
          journey so there are still a number of decisions to be made and your voice will be key as they grow.
        </p>
        <p className="bottom-text">
          DevOps is a way of life here and engineers who embody the DevOps philosophy will find
          the culture a familiar one. In time, as we onboard new customers, we expect to grow
          the team under you so there will likely be opportunities to develop into leadership
          roles as they become available.
        </p>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">About you</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>You should be a tenacious problem solver.</li>
          <li>You love working within a strong team and communicate well to the team’s output.</li>
          <li>
            You should have experience with process automation and Infrastructure as Code (IaC) practices
            (Terraform is currently the tool of choice).
          </li>
          <li>
            You must have a demonstrable grasp of microservices and container technology (ideally Docker)
            and the tools that support it in production deployments (Kubernetes etc.)
          </li>
          <li>
            You should be adept with Continuous Integration tools (currently Travis) and understand how they
            interact with the release pipeline.
          </li>
          <li>
            Monitoring and performance management should be second nature for you and you should be adept with
            a suite of tools for achieving our infrastructure performance goals.
          </li>
          <li>
            You should have a variety of experience working with typical engineering pipeline tools including
            version control, code-quality and testing tools.
          </li>
          <li>
            You should have a high level of ability with Linux command line, bash and other system
            configuration scripting languages.
          </li>
          <li>
            You should have some experience working with public cloud infrastructure, ideally,
            GCP but AWS and Azure knowledge is also relevant.
          </li>
        </ul>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">Ideally, you will also have</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>Experience with NodeJS deployments.</li>
          <li>Experience with Java deployments.</li>
          <li>Good understanding of REST and how to consume an API.</li>
          <li>Experience with Stackdriver for monitoring.</li>
          <li>Experience with Agile development methodologies. </li>
        </ul>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">What you can expect from us</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>Salary: Starting at £55k+ depending on experience.</li>
          <li>Great central Edinburgh location (Fountainbridge).</li>
          <li>Dynamic start-up culture.</li>
          <li>Flexible working environment (including remote working where required).</li>
          <li>An open and welcoming culture with a great appetite for new technology and ideas.</li>
        </ul>
        <p className="end-text">If you think this is for you, we&apos;d love to hear from you.</p>
      </Fade>
    </VacancyTemplate>
  );
};

export default Engineer;
