import React, { useState, Fragment } from 'react';
import './Platform.css';
import {
  Container, Button, Row, Col, Card, Form,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { toggleClick, setActiveTab } from '../../store/actions';
import harvestID from '../../img/products/harvest-id/harvest-ID.svg';
import trustflight from '../../img/products/trustflight/trustflight.svg';
import distilledID from '../../img/products/distilled-id/distilled-ID.svg';
import adaptable from '../../img/platform/adaptable.svg';
import transparent from '../../img/platform/transparent.svg';
import trustable from '../../img/platform/trustable.svg';

const Platform = (props) => {
  const [name, setName] = useState('');
  const [validName, setValidName] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const [validCompanyName, setValidCompanyName] = useState(true);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const DELAYSHORT = 200;
  const DELAY400 = 400;
  const DELAY600 = 600;

  const isEmailValid = function isEmailValid(emailAddress) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(emailAddress).toLowerCase());
  };

  const isFormValid = function isFormValid() {
    const emptyForm = name === '' || email === '' || companyName === '';
    const valid = validName && validEmail && validCompanyName;
    return valid && !emptyForm;
  };

  const { setTab, togglePress } = props;
  return (
    <Fragment>
      <div className="platform">
        <div className="platform-container">
          <div className="platform-overview-bg">
            <Row className="platform-body">
              <Col md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 9 }}>
                <Fade>
                  <h1>
                    The arc-net platform is built to provide a secure, immutable, and trustable
                    means to control and share your product data while driving and deriving value
                    from consumer engagement.
                  </h1>
                </Fade>
              </Col>
              <Col md={{ span: 12 }} lg={{ span: 4 }} xl={{ span: 3 }}>
                <Fade>
                  <div className="join-link">
                    <Link to="/contact" className="join-us arrow-link">
                      <span>Get in touch</span>
                    </Link>
                  </div>
                </Fade>
              </Col>
            </Row>
          </div>
          <div className="bright-bg platform-key-features">
            <Row className="platform-body-row">
              <Col className="left-col" xs={12} lg={8}>
                <Fade bottom>
                  <h1 className="header-title-text">Blockchain technology</h1>
                </Fade>
                <Fade>
                  <p>
                    Over the past decade, blockchain networks have been emerging as innovative and
                    disruptive technology in a number of sectors. Seeing the power of this
                    technology to bring immutable and transparent data sharing to the complex world
                    of supply chain and product provenance, arc-net has placed blockchain technology
                    at the very core of our platform.
                  </p>
                </Fade>
                <Fade>
                  <p>
                    Blockchain continues to be an emerging system and arc-net’s use of it evolves
                    alongside the changes of the tech. However, sometimes we need to work within
                    existing IT infrastructure, or meet certain specific business needs. To this
                    end, arc-net abstracts away from any one blockchain implementation, allowing us
                    to quickly adapt to this rapidly changing environment.
                  </p>
                </Fade>
              </Col>
              <Col xs={12} lg={4} className="img-wrapper">
                <Fade delay={200}>
                  <div className="blockchain-img" />
                </Fade>
              </Col>
            </Row>
            <Row className="platform-body-row">
              <Col xs={{ span: 12, order: 2 }} lg={{ span: 4, order: 1 }}>
                <Fade>
                  <div className="integration-img" />
                </Fade>
              </Col>
              <Col xs={{ span: 12, order: 1 }} lg={{ span: 8, order: 2 }}>
                <Fade bottom>
                  <h1 className="header-title-text" id="fantastic">
                    Integration
                  </h1>
                </Fade>
                <Fade>
                  <p>
                    Arc-net provides multiple interfaces, mobile apps, and convenience methods for
                    capturing your data and production processes. However, we believe that our
                    process are at their most efficient and effective when they seamlessly integrate
                    with yours. To achieve this, our platform is built with an “API first”
                    mentality. Our whole application can be operated and managed through our APIs
                    and even our own interfaces communicate with the platform through the public
                    facing APIs. This allows you a direct connection from your existing systems to
                    ours reducing data repetition and increasing integrity.
                  </p>
                </Fade>
              </Col>
            </Row>
            <Row className="platform-body-row">
              <Col className="left-col" xs={12} lg={7}>
                <Fade bottom>
                  <h1 className="header-title-text">IOT</h1>
                </Fade>
                <Fade>
                  <p>
                    The Internet Of Things movement is increasing the fidelity, quantity, and depth
                    of data collection options available to the market. Product provenance and
                    supply chain logistics are areas where this technology can really shine when
                    integrated effectively and the arc-net platform has a number of out of the box
                    options including GPS trackers, ready to be added to your system.
                  </p>
                </Fade>
              </Col>
              <Col xs={12} lg={5}>
                <Fade delay={200}>
                  <div className="iot-img" />
                </Fade>
              </Col>
            </Row>
          </div>
          <div className="dark-bg">
            <Row className="heading-wrapper">
              <Col md={{ span: 12 }}>
                <Fade bottom>
                  <h1 className="header-title-text">What makes our arc-net platform unique</h1>
                </Fade>
                <Fade bottom delay={500}>
                  <div className="border-gradient border-center" />
                </Fade>
              </Col>
            </Row>
            <Row className="unique-features">
              <Col md={{ span: 12 }} lg={{ span: 4 }}>
                <Fade delay={300}>
                  <Card className="card-dark">
                    <Card.Body>
                      <div className="card-image-center">
                        <img src={trustable} className="feature-icon" alt="Trustable Solution" />
                      </div>
                      <Card.Title className="card-text-center">
                        <h3 className="card-header-text">Trustable</h3>
                      </Card.Title>
                      <Card.Text className="card-regular-text">
                        Data recorded on the blockchain cannot be removed or changed providing you
                        with a full audit trail of your production journey.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Fade>
              </Col>
              <Col md={{ span: 12 }} lg={{ span: 4 }}>
                <Fade delay={600}>
                  <Card className="card-dark">
                    <Card.Body>
                      <div className="card-image-center">
                        <img src={adaptable} className="feature-icon" alt="Adaptable Solution" />
                      </div>
                      <Card.Title className="card-text-center">
                        <h3 className="card-header-text">Adaptable</h3>
                      </Card.Title>
                      <Card.Text className="card-regular-text">
                        Our customisable rules engine allows us to map the journey of even very
                        complex processes providing traceability for any industry.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Fade>
              </Col>
              <Col md={{ span: 12 }} lg={{ span: 4 }}>
                <Fade delay={900}>
                  <Card className="card-dark">
                    <Card.Body className="card-padding">
                      <div className="card-image-center">
                        <img
                          src={transparent}
                          className="feature-icon"
                          alt="Transparent Solution"
                        />
                      </div>
                      <Card.Title className="card-text-center">
                        <h3 className="card-header-text">Transparent</h3>
                      </Card.Title>
                      <Card.Text className="card-regular-text">
                        Data can be easily shared internally through your dedicated blockchain
                        network and our Storybooks provide the perfect way to engage with your
                        consumers.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Fade>
              </Col>
            </Row>
          </div>
          <Container className="features-container">
            <Row>
              <Col md={{ span: 12 }}>
                <Fade bottom>
                  <h2>A single source of truth</h2>
                </Fade>
                <Fade delay={500}>
                  <div className="border-gradient" />
                </Fade>
              </Col>
              <Col md={{ span: 12 }} lg={{ span: 6 }}>
                <Fade>
                  <p>
                    Your customers are more discerning than ever and are demanding more transparency
                    about the products they purchase. With supply chains becoming more complex and
                    global, the challenge to maintain a single view of your data is only increasing.
                  </p>
                  <p>
                    The arc-net platform can help you get control of your data, providing you with
                    “a single source of truth” which you can use to keep your customers informed and
                    your processes streamlined.
                  </p>
                </Fade>
              </Col>
              <Col md={{ span: 12 }} lg={{ span: 4, offset: 1 }}>
                <h3>Cloud Provider Agnostic</h3>
                <div className="logo-wrapper">
                  <div id="gcp" className="logo" />
                  <div id="aws" className="logo" />
                  <div id="azure" className="logo" />
                </div>
                <h3>Blockchain Provider Agnostic</h3>
                <div className="logo-wrapper">
                  <div id="ethereum" className="logo" />
                  <div id="multichain" className="logo" />
                  <div id="hyperledger" className="logo" />
                </div>
                <h3>API Enabled</h3>
                <h3>IOT Compatible</h3>
              </Col>
            </Row>
          </Container>
          <Container className="solutions-wrapper">
            <Row className="header-wrapper">
              <Col className="header-row" md={{ span: 12 }}>
                <Fade bottom>
                  <h2>We have a solution for your industry</h2>
                </Fade>
                <Fade bottom delay={500}>
                  <div className="border-gradient border-center" />
                </Fade>
              </Col>
            </Row>
            <Row className="solution-row gradient-box">
              <Col lg="4" className="gradient-column">
                <Fade delay={DELAYSHORT}>
                  <LinkContainer
                    onClick={() => {
                      togglePress(true);
                      setTab('#distilledid');
                    }}
                    to={{ pathname: '/' }}
                  >
                    <div className="inner">
                      <h4>Distilleries & Breweries</h4>
                      <img src={distilledID} alt="distilledid" />
                    </div>
                  </LinkContainer>
                </Fade>
              </Col>
              <Col lg="4" className="gradient-column">
                <Fade delay={DELAYSHORT}>
                  <LinkContainer
                    onClick={() => {
                      togglePress(true);
                      setTab('#harvestid');
                    }}
                    to={{ pathname: '/' }}
                  >
                    <div className="inner">
                      <h4>Food and Farming</h4>
                      <img src={harvestID} alt="harvestID" />
                    </div>
                  </LinkContainer>
                </Fade>
              </Col>
              <Col lg="4" className="gradient-column">
                <Fade delay={DELAYSHORT}>
                  <LinkContainer
                    onClick={() => {
                      togglePress(true);
                      setTab('#other');
                    }}
                    to={{ pathname: '/' }}
                  >
                    <div className="inner">
                      <h4>Your Business</h4>
                    </div>
                  </LinkContainer>
                </Fade>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="contact-wrapper">
              <Col md={{ span: 12 }} lg={{ span: 6 }}>
                <Fade bottom>
                  <h2>A platform for your products</h2>
                </Fade>
                <Fade>
                  <h4>
                    Contact us to find out how we can deploy the arc-net platform to bring
                    <span> traceability</span>
                    ,
                    <span> provenance</span>
                    , and
                    <span> consumer engagement </span>
                    to your product portfolio.
                  </h4>
                </Fade>
              </Col>
              <Col md={{ span: 12 }} lg={{ span: 6 }}>
                <form action="https://formspree.io/info@arc-net.io" method="POST">
                  <Fade bottom>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formname">
                        <Form.Label>NAME</Form.Label>
                        <Form.Control
                          test="form-name"
                          name="name"
                          isInvalid={!validName}
                          type="text"
                          placeholder="Please enter your name"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            setValidName(e.target.value.length > 0);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a name
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </Fade>
                  <Fade bottom delay={DELAYSHORT}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formcompany">
                        <Form.Label>COMPANY NAME</Form.Label>
                        <Form.Control
                          test="form-company"
                          name="company name"
                          isInvalid={!validCompanyName}
                          type="text"
                          placeholder="Please enter your company name"
                          value={companyName}
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                            setValidCompanyName(e.target.value.length > 0);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a name
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </Fade>
                  <Fade bottom delay={DELAY400}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="email">
                        <Form.Label>WORK EMAIL ADDRESS</Form.Label>
                        <Form.Control
                          test="form-email"
                          name="email address"
                          isInvalid={!validEmail}
                          type="text"
                          placeholder="Please enter your email address"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setValidEmail(isEmailValid(e.target.value));
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid email address
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </Fade>

                  <Fade bottom delay={DELAY600}>
                    <Button
                      disabled={!isFormValid()}
                      className="register-button button-hover"
                      type="submit"
                      test="form-submit"
                    >
                      REGISTER INTEREST
                    </Button>
                  </Fade>
                </form>
              </Col>
            </Row>
          </Container>
        </div>
        <Fade>
          <section className="block-clients">
            <div className="wrapper">
              <ul className="companies">
                <li>
                  <div className="pwc-logo logo" />
                </li>
                <li>
                  <div className="hendrix-logo logo" />
                </li>
                <li>
                  <div className="ms-logo logo" />
                </li>
                <li>
                  <div className="wgs-logo logo" />
                </li>
                <li>
                  <div className="cranswick-logo logo" />
                </li>
                <li>
                  <div className="lot-logo logo" />
                </li>
              </ul>
            </div>
          </section>
        </Fade>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  clicked: state.clicked,
  activeTab: state.activeTab,
});

const mapDispatchToProps = dispatch => ({
  togglePress: clickState => dispatch(toggleClick(clickState)),
  setTab: activeTab => dispatch(setActiveTab(activeTab)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Platform);
