import React from 'react';
import {
  Container, Row, Col, Form, InputGroup, Button,
} from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import arrow from '../../img/icons/arrow-right-icon.svg';
import './News.css';
import blogs, { featured } from '../../News';
import NewsPost from '../common/NewsPost';
import featuredImage from '../../img/blog/outsell-featured.jpg';

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      hasMore: true,
    };
    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.state.blogposts = blogs.slice(0, 3);
    this.state.data = blogs
      .slice(3, blogs.length)
      .reverse()
      .map(item => (
        <Col className="news-item" lg={{ span: 4, offset: 0 }} key={`blog-col-${item.heading}`}>
          <NewsPost
            image={item.image}
            title={item.heading}
            content={item.content}
            path={item.path}
            next={item.next}
            previous={item.previous}
          />
        </Col>
      ));
  }

  fetchMoreData() {
    const { data, items } = this.state;
    if (data.length === 0) {
      this.setState({ hasMore: false });
      return;
    }
    this.setState({
      items: items.concat(data.pop()),
    });
  }

  render() {
    const { blogposts, items, hasMore } = this.state;
    return (
      <div className="news">
        <Container fluid className="featured-row">
          <Container>
            <Link to={`/news/${featured.path}`} className="featured-link">
              <Row>
                <Col md={6} className="image-col">
                  <div
                    style={{ backgroundImage: `url(${featuredImage})` }}
                    className="featured-image"
                    alt=" featured"
                  />
                </Col>
                <Col md={6} className="featured-content">
                  <Fade>
                    <h2>{featured.heading}</h2>
                    <h3>{featured.content}</h3>

                    <div className="border-gradient" />
                  </Fade>
                </Col>
              </Row>
            </Link>
          </Container>
        </Container>
        <Container>
          <Row className="news-row">
            {blogposts.map(item => (
              <Col
                key={`blog-col-${item.heading}`}
                className="news-item"
                lg={{ span: 4, offset: 0 }}
              >
                <NewsPost
                  image={item.image}
                  title={item.heading}
                  content={item.content}
                  path={item.path}
                  next={item.next}
                  previous={item.previous}
                />
              </Col>
            ))}
          </Row>
          <InfiniteScroll
            scrollThreshold={0.64}
            className="news-row row"
            dataLength={items.length}
            next={this.fetchMoreData}
            hasMore={hasMore}
          >
            {items.map(item => item)}
          </InfiniteScroll>

          <Row className="stay-updated">
            <Col lg={{ span: 6, offset: 3 }}>
              <Fade bottom>
                <h2>Stay updated with arc-net</h2>
              </Fade>
            </Col>
          </Row>

          <Row className="stay-updated-copy">
            <Col lg={{ span: 8, offset: 2 }}>
              <Fade bottom>
                <h3>
                  Enter your email address in the box below to keep up to date with the latest news
                  and stories from arc-net, direct to your inbox.
                </h3>
                <h3>
                  By signing up, you will receive emails about arc-net products and you agree to our
                  terms & conditions and privacy policy.
                </h3>
              </Fade>
            </Col>
          </Row>
          <Fade bottom>
            <Row className="form-row">
              <Col lg={{ span: 6, offset: 3 }} className="form-col">
                <form
                  action="https://arc-net.us15.list-manage.com/subscribe/post?u=187d76f3671d5e99eee582cbd&amp;id=1a63457dfb"
                  method="post"
                >
                  <Form.Group controlId="validationEmail" className="form">
                    <InputGroup>
                      <Form.Control
                        type="text"
                        id="mce-EMAIL"
                        name="EMAIL"
                        placeholder="yourname@email.com"
                        required
                        className="form-field"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email address.
                      </Form.Control.Feedback>
                      <Button type="submit" className="arrow-button">
                        <img src={arrow} alt="submit" className="arrow" />
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </form>
              </Col>
            </Row>
          </Fade>
        </Container>
      </div>
    );
  }
}

export default News;
