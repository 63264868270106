import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import "./Clients.css";

export default function Clients() {
  return (
    <div className="clients">
      <Container>
        <Row>
          <Col lg={{ span: 12 }}>
            <Fade bottom>
              <h2>Our clients</h2>
            </Fade>
            <Row>
              <div className="one-fourth column">
                <div className="hover-box-wrapper">
                  <div className="client-img ab" />
                </div>
              </div>
              <div className="one-fourth column">
                <div className="hover-box-wrapper">
                  <div className="client-img basf" />
                </div>
              </div>
              <div className="one-fourth column">
                <div className="hover-box-wrapper">
                  <div className="client-img cranswick" />
                </div>
              </div>
            </Row>
            <Row>
              <div className="one-fourth column">
                <div className="hover-box-wrapper">
                  <div className="client-img downstream" />
                </div>
              </div>
              <div className="one-fourth column">
                <div className="hover-box-wrapper">
                  <div className="client-img hendrix" />
                </div>
              </div>
              <div className="one-fourth column">
                <div className="hover-box-wrapper">
                  <div className="client-img icb" />
                </div>
              </div>
              <div className="one-fourth column">
                <div className="hover-box-wrapper">
                  <div className="client-img kininvie" />
                </div>
              </div>
            </Row>
            <Row>
              <div className="one-fourth column">
                <div className="hover-box-wrapper">
                  <div className="client-img ms" />
                </div>
              </div>
              <div className="one-fourth column">
                <div className="hover-box-wrapper">
                  <div className="client-img pwc" />
                </div>
              </div>
              <div className="one-fourth column">
                <div className="hover-box-wrapper">
                  <div className="client-img wgs" />
                </div>
              </div>
              <div className="one-fourth column">
                <div className="hover-box-wrapper">
                  <Link to="/contact">
                    <div className="client-img you" />
                  </Link>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
