import React from 'react';
import NewsTemplate from '../components/common/NewsTemplate';

export default function merryChristmas(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Arc-net is launching Distilled ID!"
      image={require('../img/blog/launching-distilled-id-sq.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        Arc-net is bringing its trusted, blockchain backed, traceability platform to the spirits drinks industry with a new distillery management platform.
      </p>
      <p>
        Distilled ID will leverage arc-net’s end-to-end traceability system and include special features unique to spirit production including stock and dry goods management,cask management, production control and recording, bottling and labeling control and finally logistics and distribution tracking. And best of all, our customers will continue to be able to share their unique brand and spirit provenance through our Storybook interfaces providing dynamic and interactive user experiences to share their stories and generate feedback.
      </p>
      <p>
        Distilled ID comes with a completely redesigned user interface which will be comfortable to anybody with experience working in the spirit drinks and whisky industries and completely removes the need for unreliable paper or spreadsheet data capture. We can then automatically generate all the HMRC spirit production and tax compliance paperwork required.
      </p>
      <p>
        And we’re not stopping there! Distilled ID will shortly be able to integrate with your accounting software allowing automatic tax reporting, not just for your drinks production but for all goods including gift shop and visitor center sales.
      </p>

      <p>
        We’re also developing new and exciting analytics and business intelligence tools to allow you to gain special insight into both your internal production as well as customer behaviour unlocking efficiency savings and market opportunities.
      </p>
      <p>
        Thinking of joining us? You will be in good company:
      </p>
    </NewsTemplate>
  );
}
