import React from 'react';
import { Link } from 'react-router-dom';
import NewsTemplate from '../components/common/NewsTemplate';

export default function riskManagement(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Enterprise Risk Management in the Food Industry"
      image={require('../img/blog/riskManagement.png')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        Our global food supply is growing more complex by the year. As demand for high quality,
        extremely fresh and sustainable food rises, the food supply web is coming under intense
        pressure to meet expectations. This presents a number of challenges for businesses operating
        in the food industry, but for those companies who are able to apply effective risk
        management strategies there are huge opportunities to stand out from the crowd and unlock a
        unique competitive advantage.
      </p>
      <p>
        According to Deloitte, taking “a risk-intelligent approach” is a critical aspect of a
        successful business, supporting management in delivery of the strategy, managing costs,
        attracting investment, making better decisions and responding to risk.
      </p>
      <p>
        In their report on Enterprise Risk Management, Deloitte outline a range of “optimal risk
        taking” companies need to reach to balance their key risks effectively. This ‘Sweet Spot’ is
        reached by ensuring that risks taken by an organisation are analysed effectively, ensuring
        that they are the correct risks to take and that they are appropriately managed.
      </p>
      <p>
        It’s important to highlight the balancing aspect of this definition. This calls for a
        development of our understanding of risk in business, moving from a simple analysis and
        mitigation of risk to embracing a culture of effective risk taking. As Deloitte put it
        “organisations create value by taking risks and lose value by failing to manage them.”
      </p>
      <p>
        So how does this apply to our food system? In his report,The Role of Enterprise Risk
        Management in Food Fraud Prevention Dr John Spink, Director & Assistant Professor of the
        Food Fraud Initiative at Michigan State University, tells us that “an enterprise-wide risk
        perspective such as Enterprise Risk Management (ERM) provides a widely-adopted and
        structured approach for managing Food Fraud prevention.”
      </p>
      <p>
        Spink’s report emphasis that addressing Food Fraud does not create new risks, rather it
        proactively identifies a pre-existing ‘inherent risk’ in the business. The report also calls
        for an evolution from the traditional “return-on-investment (ROI)” financial accounting
        practices to a more specialised enterprise wide resource allocation decision making process.
        This process, known as the Committee of Sponsoring Organisations of the Treadway Comission
        (COSO) focuses on three interrelated subjects of Enterprise Risk Management, internal
        control and fraud deterrence.
      </p>
      <p>
        We can see a link between this approach to ERM and the “risk intelligent approach” proposed
        by Deloitte in the COSO concept of “risk appetite”. COSO defines risk appetite as “the
        amount of risk, on a broad level, an organisation is willing to accept in pursuit of value”
        – immediately this reminds us of Deloitte’s use of the “Sweet Spot” where companies analyse
        risks to ensure that only the correct risks are taken and efficiently managed.
      </p>
      <p>
        In practical terms, this can help guide the food industry in the steps they take to protect
        themselves from fraud. For instance, while it may appear too big a financial risk for a
        processor to remodel their production process, in order to implement new traceability
        technologies, the approach to Enterprise Risk Management encouraged by both Deloitte and
        COSO show that such a move would fall into the “Sweet Spot” and be just the right level of
        manageable risk to accept in order to create value by limiting the potential impact of
        fraud.
      </p>
      <p>
        Another aspect of food fraud prevention that the report focuses on surrounds “Laws,
        Regulations, Certifications and Standards”. The report outlines that in some current
        legislation related to food fraud, such as the US Food Safety Modernisation Act of 2011
        (FSMA), a health hazard is required for there to be a violation of the law. As the report
        points out, that means that incidents like the 2014 UK horsemeat scandal, would not
        technically violate this law, even though it was a notorious and damaging aspect of fraud.
      </p>
      <p>
        This highlights the difficulty for government agencies to be effective in the prevention of
        food fraud, given that their focus is primarily on after-the-fact responses i.e.
        investigation, compliance, enforcement and prosecution. The need to focus on initial
        prevention of food fraud incidents is particularly important given that recalls have been
        described as “the food industry’s biggest threat to profitability.”
      </p>
      <p>
        In a time when consumers are demanding more transparency and information about where their
        food comes from, companies who can display effective risk management strategies unlock
        consumer trust and brand loyalty.
      </p>
      <p>
        Consumer trust in the role of food manufactures in the food chain has taken considerable
        damage from recent incidents of fraud. Indeed, only 33% of consumers trust food
        manufacturers to ensure that food is safe to eat.
      </p>
      <p>
        This allows producers to show the steps they have taken to mitigate the risk to consumers
        from fraud and present themselves as a trustworthy and reliable alternative to those who
        consumers already mistrust.
      </p>
      <p>
        Effective risk management is of course a difficult balance to strike, businesses must
        constantly be attuned to the potential pitfalls that exist in their industry, and yet at the
        same time must not allow themselves to be frightened into inactivity, stifling their growth.
      </p>
      <p>
        This is especially true for the food industry where the stakes are perhaps higher than any
        other, due to the direct impact the industry has on public health. The reward for those who
        get it right however make this aforementioned “sweet spot” a place worth striving for.
      </p>
      <p>
        Want to know more about protecting your business from risk?
      </p>
      <Link to="/contact">Get in touch with arc-net today</Link>
    </NewsTemplate>
  );
}
