import React from 'react';
import './TabProducers.css';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

export default function TabProducers() {
  return (
    <div className="tab-producers">
      <Container fluid>
        <Row className="featured-row">
          <Col xs={{ span: 12, order: 2 }} md={{ span: 5, order: 1 }} className="featured-img-wrapper">
            <Slide left>
              <div className="demo-inset">
                <Fade delay={1000}>
                  <div className="get-started">
                    <h5>Find out how our solution can work with your products</h5>
                    <div className="register">
                      <Link to="/contact" className="arrow-link-wrapper">
                        <h3 className="arrow-link light">GET IN TOUCH</h3>
                      </Link>
                    </div>
                  </div>
                </Fade>
              </div>
            </Slide>
          </Col>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} className="content-wrapper">
            <Fade bottom>
              <h2>Your Business</h2>
            </Fade>
            <Fade delay={200}>
              <h3>
                The arc-net platform is hugely customisable and while we’ve developed specialised
                tools for the food and drinks industry, our service can be adapted to meet the needs
                of any business quickly and simply.
              </h3>
            </Fade>
            <Fade delay={400}>
              <p>
                The platform has, at its core, a robust rules engine which can describe any system
                of events. We can use this system to track anything from FMCG to high value,
                precision engineered products and, if your finished article ends up in the hands of
                consumers, you can share its unique data through our custom Storybook interfaces.
              </p>
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
