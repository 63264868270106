import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import arrow from '../../img/icons/arrow-right-icon.svg';
import './NewsPost.css';

export default function NewsPost(props) {
  const {
    path, next, previous, image, title, content,
  } = props;
  return (
    <Fade>
      <Link to={{
        pathname: `/news/${path}`,
        state: {
          next,
          previous,
        },
      }}
      >
        <div className="news-post">

          <div className="image" style={{ backgroundImage: `url(${image})` }} />
          <div className="text">
            <div className="title">
              {title}
            </div>
            <div className="content">
              {content}
            </div>
          </div>
          <div className="arrow-container">
            <img src={arrow} className="arrow" alt="" />
          </div>
        </div>
      </Link>
    </Fade>
  );
}
