import React from 'react';
import './BrandProtection.css';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import endToEnd from '../../img/icons/end-to-end.svg';
import consumerEngagement from '../../img/icons/consumer-engagement.svg';
import productSecurity from '../../img/icons/product-security.svg';

export default function BrandProtection() {
  return (
    <div className="brand-protection">
      <Container>
        <Row className="brand-protection-row">
          <Col md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }}>
            <Fade bottom>
              <h2>Brand protection through security and transparency</h2>
            </Fade>
          </Col>
          <Col md={{ span: 10, offset: 1 }}>
            <Fade>
              <h3>
                Arc-net brings you a simple way to describe your product’s journey and share your
                unique brand with your customers. Built on top of blockchain technology, the arc-net
                suite provides a scalable, transparent, track and trace solution with unique
                end-consumer engagement giving you access to valuable behaviour insight and
                analysis.
              </h3>
            </Fade>
          </Col>
        </Row>
        <Row className="card-row">
          <Col lg={{ span: 4 }} className="card-col">
            <Fade>
              <div className="card-content">
                <div className="card-text">
                  <img
                    src={endToEnd}
                    className="feature-icon"
                    alt="End To End Traceability Solution"
                  />
                  <h3>End-To-End Traceability</h3>
                  <h4>
                    The arc-net platform allows for complete tracking of your unique assets. Our
                    modular interface lets you visualise your complete end-to-end process or focus
                    down on specific key data points such as stock control.
                  </h4>
                </div>
              </div>
            </Fade>
          </Col>
          <Col lg={{ span: 4 }} className="card-col">
            <Fade delay={500}>
              <div className="card-content">
                <div className="card-text">
                  <img
                    src={consumerEngagement}
                    className="feature-icon"
                    alt="Consumer Engagement"
                  />
                  <h3>End User Engagement</h3>
                  <h4>
                    Arc-net’s unique “Storybook” interfaces shares select details of your process
                    with your customers. As end consumers interact with your bespoke Storybook,
                    you’ll gain unique insights into their behaviours bringing your in depth
                    business intelligence.
                  </h4>
                </div>
              </div>
            </Fade>
          </Col>
          <Col lg={{ span: 4 }} className="card-col">
            <Fade delay={1000}>
              <div className="card-content">
                <div className="card-text">
                  <img src={productSecurity} className="feature-icon" alt="Product Authenticity" />
                  <h3>Product Authenticity</h3>
                  <h4>
                    Leveraging the immutability of the blockchain that underpins our platform and by
                    making use QR and NFC (or other consumer scannable tech), arc-net can provide
                    assurance to your customers that they are purchasing genuine products and
                    through data analytics we can help you identify possible parallel-trading and
                    grey market vectors.
                  </h4>
                </div>
              </div>
            </Fade>
          </Col>
        </Row>
        <Fade bottom>
          <Row>
            <Col className="more-row">
              <Link to="/platform" className="arrow-link-wrapper">
                <h3 className="arrow-link dark">MORE ABOUT THE PLATFORM</h3>
              </Link>
            </Col>
          </Row>
        </Fade>
      </Container>
    </div>
  );
}
