import React from 'react';
import '../Vacancy.css';
import Fade from 'react-reveal/Fade';
import VacancyTemplate from '../../common/VacancyTemplate';
import separator from '../../../img/icons/paragraph-separator.svg';

const BusinessDevelopmentManager = () => {
  const jobTitle = 'Business Development Manager';
  const jobDescription = `Arc-net is looking for a talented Business Development Manager to join our growing development team
  in Edinburgh.`;
  return (
    <VacancyTemplate
      jobTitle={jobTitle}
      jobDescription={jobDescription}
    >
      <Fade bottom>
        <h1 className="bottom-header-text">Who we are</h1>
      </Fade>
      <Fade>
        <p className="bottom-text">
          Arc-net is a tech startup based in Edinburgh, the beautiful capital in the heart of
          Scotland. We are using blockchain to help food & drink producers better understand their
          supply chain and customers. Our end-to-end platform provides full traceability, process
          control analytics and compliance support. In 2017, we launched the world’s first
          blockchain-enabled beer, allowing the brewer and customers to connect in a way not
          possible before, and we are doing the same with Scottish whisky. We are now scaling our
          platform for use in other areas of the food and drink industry, working towards our goal
          of bringing consumers and producers closer together.
        </p>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">Your key accountabilities</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>
            Possess a strong understanding of our service platform, our competitors and our
            service positioning.
          </li>
          <li>
            Create and execute exceptional personal sales and profit plans to exceed agreed
            business objectives and targets.
          </li>
          <li>
            Use consultative selling methods to really understand our clients’ needs and develop
            effective solutions to meet and exceed their requirements.
          </li>
          <li>
            Develop and engage a wide network of distillery clients using existing CRM tools and
            your own contacts.
          </li>
          <li>Provide accurate revenue forecasting and reporting.</li>
          <li>
            Participate in the ongoing development of the platform by mapping product features to
            the client’s business issue.
          </li>
        </ul>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">Your key competencies</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>
            Strong inter-personal skills, including listening, engaging, negotiating and
            inspiring.
          </li>
          <li>Excellent influencing skills.</li>
          <li>
            Effective and persuasive verbal and written communication skills including pitch
            writing.
          </li>
          <li>Analytical and numerate with excellent commercial acumen.</li>
          <li>A good understanding of technology solutions as a service.</li>
          <li>Effective sales planning skills.</li>
          <li>Ability to prioritise client opportunities through pipeline to close sales.</li>
          <li>Quick and innovative thinker.</li>
          <li>Self-starter, able to work without direct supervision.</li>
          <li>Highly motivated and resilient.</li>
          <li>Commitment to our values and behaviours.</li>
        </ul>
        <p className="end-text">Starting salary: £40,000</p>
      </Fade>
    </VacancyTemplate>
  );
};

export default BusinessDevelopmentManager;
