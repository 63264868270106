/* eslint-disable global-require */
import React from 'react';
import NewsTemplate from '../components/common/NewsTemplate';

export default function adelphi(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Arc-net nominated for Outsell 50 Award"
      image={require('../img/blog/outsell-featured.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        Earlier in 2019, arc-net was added to the “Outsell 50”, a list of companies (mostly in the
        tech and data industries) making waves in their industries, creating disruption and
        generating new innovative business. Each year, Outsell selects the top ten from their
        list to take part in the Emerging Company Growth Tank, hosted during their signature
        event and this year arc-net has been selected!
      </p>
      <p>
        Next week, our own Drew Lyall will be traveling to Washington D.C. to attend the Outsell
        Signature Event (now in its 13th year). The event caters to CEOs and Directors in the
        global data and information industry and delivers keynotes and presentations discussing
        a wealth of current issues and practices that affect our businesses today and will affect
        them in the future.
      </p>
      <p>
        While there, he will be presenting our company, its market, our solution, and our values
        to the Outsell audience in our pitch to win the Emerging Company Growth Award. Corvium
        Inc came away as last year’s winner after successfully demonstrating their potential
        to shape the food safety business and the market available to them to achieve their
        goal of protecting food brands against recall.
      </p>
      <p>
        Arc-net hopes to use this fantastic opportunity to raise awareness of our unique traceability
        tools in the US. Both the food and drink and aviation markets in America have the
        same complexities and difficulties as they do in the UK only amplified through their
        relative sizes. Arc-net has been developing our solutions to deal with the volume and
        availability requirements demanded by organisations of this scale and our platform is ready
        to provide service at scale.
      </p>
      <p>
        The event will be held on October 3rd which is coming up fast. Wish us luck!
      </p>
    </NewsTemplate>
  );
}
