import React from 'react';
import NewsTemplate from '../components/common/NewsTemplate';

export default function brandOath(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Arc-net and Brand Oath to offer creative innovations for producers worldwide"
      image={require('../img/blog/brand-oath-sq.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>Brand Oath will deliver branding strategy for arc-net’s clients.</p>
      <p>
        The Scottish based international branding agency, Brand Oath, enters into a strategic
        partnership with Northern Ireland based brand and product supply chain security and
        analytics start up, arc-net.
      </p>
      <p>
        Brand Oath have over 25 years’ experience producing winning brand strategies, campaigns and
        design frameworks across various sectors.
      </p>
      <p>
        They have a track record of success, ranging from assisting global cities with their high
        profile campaigns to host major international sports events such as the Olympic Games,
        through to driving growth for food and beverage brands through product development and
        positioning.
      </p>
      <p>
        With arc-net, Brand Oath will work with organisations and brand owners across a wide range
        of industries to develop effective brand strategies and provide the design frameworks to
        facilitate further digital innovation and consumer interaction.
      </p>
      <p>
        Kieran Kelly, CEO of arc-net says “I am delighted to announce our partnership with Brand
        Oath. They have a well earned reputation for creative excellence, engineering scalable and
        innovative branding solutions, ranging from huge international projects to personal bespoke
        strategies.”
      </p>
      <p>
        He continues “We are confident Brand Oath will provide an enormous competitive advantage to
        our customers in helping them to clarify and communicate their essential identity and
        values.”
      </p>
      <p>
        “Our goal is to empower our customers to unlock the potential of their supply chains,
        protect their brands and maximise enterprise value, and we look forward to Brand Oath’s
        assistance in achieving this.” He adds.
      </p>
      <p>
        Bernie Shaw-Binns, Strategy and Creative Director of Brand Oath adds, “We passionately
        believe in the power of great branding and design to engage and inspire people. With arc-net
        we believe we have met the same level of passion and enthusiasm to bring positive impacts to
        their clients. Together we are looking forward to achieving great results.”
      </p>
      <p>
        <i>About Brand Oath</i>
      </p>
      <p>
        <i>
          The communication landscape is more crowded, complex and competitive than ever. You have
          to fight for attention and cut through. By developing, creating and implementing effective
          brand communications, you can inspire your people and engage your audiences, providing
          them with compelling reasons why they should choose you over your competitors. You operate
          in a competitive world. Prepare to win with Brand Oath. Go to
          <a href="http://www.brandoath.com" target="_blank" rel="noopener noreferrer">
            {' '}
            www.brandoath.com
          </a>
        </i>
      </p>
    </NewsTemplate>
  );
}
