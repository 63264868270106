import React, { Component } from 'react';
import GoogleMap from 'google-map-react';
import marker from '../../img/icons/map-marker.svg';

export default class Map extends Component {
  onChildClick(key, props) {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${props.lat},${props.lng}`,
      '_blank',
    );
  }

  render() {
    const MapMarker = () => (
      <div
        style={{
          display: 'inline-flex',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '100%',
          transform: 'translate(-50%, -100%)',
        }}
      >
        <img src={marker} style={{ height: '45px' }} alt="location marker" />
      </div>
    );
    const mapOptions = {
      styles: [
        { elementType: 'labels.text.fill', stylers: [{ color: '#dddddd' }] },
        { elementType: 'labels.text.stroke', stylers: [{ color: '#404040' }] },
        {
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'landscape.natural',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#1D416E',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{ color: '#263c3f' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ color: '#38414e' }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#101e2e',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#404040',
            },
          ],
        },
      ],
    };

    const { center, zoom } = this.props;
    return (
      <div style={{ height: '100%' }}>
        <GoogleMap
          options={mapOptions}
          bootstrapURLKeys={{ key: 'AIzaSyCKaQznIQ3Ph72teGfOiTcF7wNqKUh10vE' }}
          defaultCenter={center}
          defaultZoom={zoom}
          onChildClick={this.onChildClick}
        >
          <MapMarker lat={55.94373} lng={-3.208896} />
        </GoogleMap>
      </div>
    );
  }
}

Map.defaultProps = {
  center: {
    lat: 54.155158,
    lng: -3.2275,
  },
  zoom: 5,
};
