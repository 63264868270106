import React from 'react';
import NewsTemplate from '../components/common/NewsTemplate';

export default function terra(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Arc-net accepted into Terra, The Food and Ag Accelerator"
      image={require('../img/blog/terra-sq.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        Arc-net are delighted to announce that they have been selected into the inaugural cohort of
        startups for Terra, the Food + Ag Tech Accelerator.
      </p>
      <p>
        Founded by Rabobank and RocketSpace, Terra is a first of its kind accelerator program
        bringing together the industry’s most disruptive startups and progressive corporations to
        fuel cross-industry innovation and set a new standard for food and agriculture. The cohort -
        with global representation from across five countries (Canada, Spain, Thailand, UK, USA) and
        industry sectors - kicks off this week on RocketSpace’s tech campus in San Francisco.
      </p>
      <p>
        Unlike traditional accelerators, Terra’s unique consortium model brings together a diverse
        group of industry leaders - both big and small - to explore and experiment with potential
        partnerships through a dedicated product validation program.
      </p>
      <p>
        Rabobank and RocketSpace, along with Terra’s corporate collaborators - AgroFresh, Beta San
        Miguel, Givaudan, Griffith Foods, Grupo Azucarero Mexico/CULTIBA and OSI Group - selected
        arc-net from a competitive applicant pool of hundreds of startups from around the world.
      </p>
      <p>
        Among other benefits, arc-net will gain brand exposure and be able to validate its product
        in collaboration with multiple industry corporations. This non-traditional accelerator model
        includes pilot testing and is structured to progress beyond a proof of concept to a
        commercially viable product.
      </p>
      <p>
        Kieran Kelly, CEO of arc-net said “I am humbled that arc-net have the opportunity to work
        alongside some of the most influential, and respected organisations working in the food and
        agriculture sector.”
      </p>
      <p>
        He continues “Rabobank and RocketSpace provide a blueprint and formula for success. We look
        forward to utilising the expertise and resources provided by the Terra program in order to
        deliver our mission of protecting the health of current and future generations by providing
        access to safe and authentic food for everyone.”
      </p>
      <p>
        “There are many unique attributes that set Terra apart from other accelerators, one being
        that the pilot program will allow us to really observe how the participating start-ups can
        scale, and how the partnership with our collaborators can help them in that process,” says
        Manuel Gonzalez, Head of Startup Innovation at Rabobank. “Terra is about both the future and
        the here and now – what the industry needs, what consumers want, what the earth requires –
        and how startups can respond to the needs of society.”
      </p>
      <p>
        Following the program’s kick off this week, arc-net will begin an eight-week tailored
        curriculum led by industry experts, followed by an eight-week product validation period.
        During the program arc-net has the unique opportunity to work among its curated community of
        tech startups which has included 18 “unicorns” valued at $1B or more.
      </p>
    </NewsTemplate>
  );
}
