import React from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import './IconRow.css';

const IconRow = (props) => {
  const { theme } = props;
  return (
    <div className="icon-row">
      <Container fluid>
        <Row className="row" id={`icon-row-${theme}`}>
          <section className="block-clients">
            <div className="wrapper">
              <ul className="companies">
                <li>
                  <div className="pwc-logo logo" />
                </li>
                <li>
                  <div className="hendrix-logo logo" />
                </li>
                <li>
                  <div className="ms-logo logo" />
                </li>
                <li>
                  <div className="wgs-logo logo" />
                </li>
                <li>
                  <div className="cranswick-logo logo" />
                </li>
                <li>
                  <div className="basf-logo logo" />
                </li>
              </ul>
            </div>
          </section>
        </Row>
      </Container>
    </div>
  );
};

export default IconRow;
