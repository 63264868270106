import Blockchain from './Blockchain';
import MerryChristmas from './MerryChristmas';
import LaunchingDistilledID from './LaunchingDistilledID';
import NewHome from './NewHome';
import Brexit from './Brexit';
import CraftBeer from './CraftBeer';
import BlockchainQuestions from './BlockchainQuestions';
import pwc from './Pwc';
import EUChina from './EUChina';
import Startup from './StartupAward';
import RiskManagement from './RiskManagement';
import FoodFraud from './FoodFraud';
import Terra from './Terra';
import BlockChainFoodIndustry from './BlockChainFoodIndustry';
import BrandOath from './BrandOath';
import Traceability from './Traceability';
import Funding from './Funding';
import MeetAnnette from './MeetAnnette';
import OutsellNominated from './OutsellNominated';
import '../img/blog/outsell-featured.jpg';

export const featured = {
  path: 'arc-net-nominated-outsell',
  content:
    'Earlier in 2019, arc-net was added to the “Outsell 50”, a list of companies (mostly in the tech and data industries) making waves in their industries, creating disruption and generating new innovative business. Each year, Outsell selects the top ten from their list to take part in the Emerging Company Growth Tank, hosted during their signature event and this year arc-net has been selected!',
  heading: 'Arc-net at  Outsell 50',
  image: require('../img/blog/outsell-featured.jpg'),
  component: OutsellNominated,
  next: 'meet-annette-our-qa-manager',
};

export default [
  {
    image: require('../img/blog/qa-featured.jpg'),
    path: 'meet-annette-our-qa-manager',
    date: '2019-04-04',
    content:
      'This blog post was written by Annette Tran, our QA Manager who wanted to share the experience of her first two weeks testing our products...',
    heading: 'Meet Annette - Our QA Manager',
    component: MeetAnnette,
    previous: 'arc-net-nominated-outsell',
    next: 'arc-nets-new-home',
  },
  {
    path: 'arc-nets-new-home',
    content:
      'Arc-net Scotland started life in what was, at the time, the Entrepreneurial Spark program provided by RBS. The “E-Spark” programme was...',
    heading: "Arc-net's new home",
    image: require('../img/blog/office.jpg'),
    component: NewHome,
    previous: 'meet-annette-our-qa-manager',
    next: 'merry-christmas-from-arc-net',
  },
  {
    image: require('../img/blog/blog-christmas-featured-s@2x.jpg'),
    path: 'merry-christmas-from-arc-net',
    date: '2018-12-17',
    content:
      'It’s been a big year here at arc-net HQ. We’ve quadrupled the size of the Edinburgh team, added thousands of new unique products ranging from whisky to...',
    heading: 'Merry Christmas from arc-net',

    component: MerryChristmas,
    previous: 'meet-annette-our-qa-manager',
    next: 'arc-net-is-launching-distilled-id',
  },
  {
    image: require('../img/blog/blog-launching-distilled-id@2x.jpg'),
    path: 'arc-net-is-launching-distilled-id',
    date: '2018-12-04',
    content:
      'Arc-net is bringing its trusted, blockchain backed, traceability platform to the spirits drinks industry with a new distillery management platform. Over a year ago...',
    heading: 'Arc-net is launching Distilled ID!',
    component: LaunchingDistilledID,
    previous: 'merry-christmas-from-arc-net',
    next: 'arc-net-participates-in-pwc-hosted-blockchain-applid-in-food-seminar',
  },
  {
    image: require('../img/blog/blog-blockchain-applied@2x.jpg'),
    path: 'arc-net-participates-in-pwc-hosted-blockchain-applid-in-food-seminar',
    date: '2018-03-11',
    content: "Arc-net participates in PwC hosted 'Blockchain applied in food' seminar",
    heading: 'Blockchain applied in the food sector',
    component: Blockchain,
    previous: 'arc-net-is-launching-distilled-id',
    next: 'brexit-and-uk-agri-food',
  },
  {
    image: require('../img/blog/blog-brexit-and-uk-agrifood@2x.jpg'),
    path: 'brexit-and-uk-agri-food',
    date: '2017-12-11',
    content:
      'Dr Stephanie Brooks arc-net Senior Consultant Whether you voted to remain or leave, Brexit will ultimately mean change for everyone. Following the UK’s decision...',
    heading: 'Brexit and UK Agri-food',
    component: Brexit,
    previous: 'arc-net-participates-in-pwc-hosted-blockchain-applid-in-food-seminar',
    next: 'arc-net-announce-world-first-craft-beer-initiative',
  },
  {
    image: require('../img/blog/blog-arc-net-announce-icb@2x.jpg'),
    path: 'arc-net-announce-world-first-craft-beer-initiative',
    date: '2017-11-28',
    content:
      'Beer ‘world-first’ by two innovative Northern Ireland companies. E Spark firms join to put new craft brew on the blockchain...',
    heading: 'Arc-net announce ‘world-first’ craft beer initiative.',
    component: CraftBeer,
    previous: 'brexit-and-uk-agri-food',
    next: 'blockchain-answering-the-questions-the-future-is-asking',
  },
  {
    image: require('../img/blog/blockchainquestion1.jpg'),
    path: 'blockchain-answering-the-questions-the-future-is-asking',
    date: '2017-11-23',
    content:
      'Our global supply chains are no longer fit for purpose. By PwC’s own estimates, food fraud is a $40 Billion a year global issue...',
    heading: 'VIDEO - Blockchain answering the questions the future is asking.',
    component: BlockchainQuestions,
    previous: 'arc-net-announce-world-first-craft-beer-initiative',
    next: 'arc-net-and-pwc-join-forces-to-use-blockchain-in-combating-food-fraud',
  },
  {
    image: require('../img/blog/pwc-featured.jpg'),
    path: 'arc-net-and-pwc-join-forces-to-use-blockchain-in-combating-food-fraud',
    date: '2018-08-30',
    content:
      'Arc-net and PwC Netherlands sign collaboration bringing blockchain as breakthrough technology for traceability...',
    heading: 'Arc-net and PwC join forces to use blockchain in combating food fraud',
    component: pwc,
    previous: 'blockchain-answering-the-questions-the-future-is-asking',
    next: 'arc-net-selected-for-eu-china-food-safety-project',
  },
  {
    image: require('../img/blog/eu-china.jpg'),
    path: 'arc-net-selected-for-eu-china-food-safety-project',
    date: '2017-06-15',
    content: 'Arc-net selected for multi-million pound EU-China Food Safety Project',
    heading: 'Arc-net announced as technology partner for one of the world’s largest food safety projects...',
    component: EUChina,
    previous: 'arc-net-and-pwc-join-forces-to-use-blockchain-in-combating-food-fraud',
    next: 'arc-net-win-startup-of-the-year-at-the-2017-digital-dna-awards',
  },
  {
    image: require('../img/blog/startup.png'),
    path: 'arc-net-win-startup-of-the-year-at-the-2017-digital-dna-awards',
    date: '2017-06-17',
    content:
      'All of us here at arc-net were humbled to take home the award for Startup of the Year at the 2017 Digital DNA Awards!..',
    heading: 'Arc-net win Startup of the Year at the 2017 Digital DNA Awards',
    component: Startup,
    previous: 'arc-net-selected-for-eu-china-food-safety-project',
    next: 'enterprise-risk-management-in-the-food-industry',
  },
  {
    image: require('../img/blog/riskManagement.png'),
    path: 'enterprise-risk-management-in-the-food-industry',
    date: '2017-05-31',
    content:
      'Our global food supply is growing more complex by the year. As demand for high quality, extremely fresh and sustainable...',
    heading: 'Enterprise Risk Management in the Food Industry',
    component: RiskManagement,
    previous: 'arc-net-win-startup-of-the-year-at-the-2017-digital-dna-awards',
    next: 'we-are-presenting-at-food-fraud-2017',
  },
  {
    image: require('../img/blog/FoodFraud.png'),
    path: 'we-are-presenting-at-food-fraud-2017',
    date: '2017-05-02',
    content:
      'Arc-net CEO Kieran Kelly will take the stage at the 2017 Food Fraud Conference in Manchester to discuss “Hacking The...',
    heading: "We're presenting at Food Fraud 2017",
    component: FoodFraud,
    previous: 'enterprise-risk-management-in-the-food-industry',
    next: 'arc-net-accepted-into-terra-the-food-and-ag-accelerator',
  },
  {
    image: require('../img/blog/terra-featured.jpg'),
    path: 'arc-net-accepted-into-terra-the-food-and-ag-accelerator',
    date: '2017-04-12',
    content:
      'Arc-net are delighted to announce that they have been selected into the inaugural cohort of startups for Terra, the Food...',
    heading: 'Arc-net accepted into Terra, The Food and Ag Accelerator',
    component: Terra,
    previous: 'we-are-presenting-at-food-fraud-2017',
    next: 'how-the-blockchain-can-revolutionise-the-food-industry',
  },
  {
    image: require('../img/blog/blockchainfood1.jpg'),
    path: 'how-the-blockchain-can-revolutionise-the-food-industry',
    date: '2017-04-10',
    content:
      'When it comes to our food supply, what we don’t know most certainly can hurt us. The World Health Organisation estimates...',
    heading: 'How the blockchain can revolutionise the food industry',
    component: BlockChainFoodIndustry,
    previous: 'arc-net-accepted-into-terra-the-food-and-ag-accelerator',
    next: 'arc-net-and-brand-oath-to-offer-creative-innovations-for-producers-worldwide',
  },
  {
    image: require('../img/blog/brandoath.jpg'),
    path: 'arc-net-and-brand-oath-to-offer-creative-innovations-for-producers-worldwide',
    date: '2017-02-17',
    content: 'Brand Oath will deliver branding strategy for arc-net’s clients. The Scottish based...',
    heading: 'Arc-net and Brand Oath to offer creative innovations for producers worldwide',
    component: BrandOath,
    previous: 'how-the-blockchain-can-revolutionise-the-food-industry',
    next: 'arc-net-traceability-innovations',
  },
  {
    image: require('../img/blog/traceability.jpg'),
    path: 'arc-net-traceability-innovations',
    date: '2017-02-13',
    content: 'Arc-net are honoured to be appearing at the Food Fraud – Global...',
    heading:
      "Arc-net's traceability innovations will take center stage at The Global Food Fraud Conference in April 2017",
    component: Traceability,
    previous: 'arc-net-and-brand-oath-to-offer-creative-innovations-for-producers-worldwide',
    next: 'arc-net-secures-funding',
  },
  {
    image: require('../img/blog/funding.jpg'),
    path: 'arc-net-secures-funding',
    date: '2017-02-07',
    content:
      'Arc-net are delighted to announce that the company has secured £2m in investment in our latest round of funding. As a result of this investment we are also pleased to...',
    heading: 'Arc-net secure £2m in funding',
    component: Funding,
    previous: 'arc-net-traceability-innovations',
  },
];
