import React from 'react';
import '../Vacancy.css';
import Fade from 'react-reveal/Fade';
import VacancyTemplate from '../../common/VacancyTemplate';
import separator from '../../../img/icons/paragraph-separator.svg';

const JuniorDesigner = () => {
  const jobTitle = 'Junior QA Engineer';
  const jobDescription = `Arc-net is looking for a talented Junior QA Engineer to
  join our growing development team in the centre of Edinburgh.`;
  return (
    <VacancyTemplate
      jobTitle={jobTitle}
      jobDescription={jobDescription}
    >
      <Fade bottom>
        <h1 className="bottom-header-text">Who we are</h1>
      </Fade>
      <Fade>
        <p className="bottom-text">
        Supply chains have become global and are constantly growing in complexity. At the same time, consumers are demanding greater transparency into the products that they purchase while trust in producers is gradually eroded by product recalls and scandals.
        </p>

        <p>
        Arc-net is a new tech start-up based in Edinburgh, the beautiful capital in the heart of Scotland. We are building a platform to track products as they pass through these elaborate supply chains and we need you to help us build the tools to support and visualise that data for our customers and their consumers. Arc-net's platform is grounded by blockchain, the technology that underpins Bitcoin and other cryptocurrencies. The distributed and essentially immutable nature of blockchain allows arc-net to offer end consumers a level of trust in their products that has been previously unavailable.

        </p>

        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">What we need</h1>
      </Fade>
      <Fade>
        <p className="bottom-text">
        To achieve arc-net's goal of delivering these services to our customers we need a passionate and committed Junior QA Engineer who will work closely with the whole Engineering team to ensure the delivery of quality software. Under the direction of the QA Manager, you will perform tasks such as manual and exploratory testing, managing defects and writing automated tests. You will have a good understanding of Agile software development practices and will champion quality processes and software.
        </p>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">Core responsibilities</h1>
      </Fade>
      <Fade bottom>
        <ul className="bottom-text list-text">
          <li>Helping the team to achieve Sprint goals and release dates by executing the test strategy for a given product or products</li>
          <li>Contributing to Scrum ceremonies such as stand-up, sprint planning and retro</li>
          <li>Ensuring that bugs are raised, classified and triaged appropriately</li>
          <li>Reviewing Requirements and Specifications to ensure that there is enough detail for testing</li>
        </ul>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">About you</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>
          You are a self-starter who is motivated to learn and continually improve
          </li>
          <li>
          You are passionate about QA and enjoy improving processes and software
          </li>
          <li>
          You have proven experience working in a QA role
          </li>
          <li>
          You understand the role of QA in an Agile team and are confident enough to challenge when you feel that quality standards are not being met
          </li>
          <li>
          You are able to cope with changing priorities and can assist where the business needs you to
          </li>
          <li>
          You are comfortable with functional black-box testing and have the curiosity to go beyond this to ensure software is tested thoroughly
          </li>
          <li>
          You will have some experience in writing and maintaining automated tests
          </li>

        </ul>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">If you have any of the following experience it’ll help</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">

          <li>Automated web front-end test tools such as Selenium, Protractor, TestCafe or Jasmine</li>
          <li>Testing iOS applications, ideally with tools such as EarlGrey</li>
          <li>Testing APIs with tools such as Postman</li>
          <li>Continuous Integration pipelines such as GitLab</li>
          <li>Working with containers and Docker</li>
          <li>Working with test frameworks such as Junit, Nunit or TestNg</li>
        </ul>
        <p className="paragraph-separator">
          <img src={separator} alt="paragraph separator" />
        </p>
      </Fade>
      <Fade bottom>
        <h1 className="bottom-header-text">What you can expect from us</h1>
      </Fade>
      <Fade>
        <ul className="bottom-text list-text">
          <li>Salary of £28,000 per annum</li>
          <li>Great Edinburgh location</li>
          <li>Dynamic start-up culture</li>
          <li>Flexible working environment (including remote working where required)</li>
          <li>Exposure to new tools and technology</li>
        </ul>
        <p className="end-text">
          If this describes you, we&apos;d love to hear from you! All applications should include a
          portfolio, CV and a cover letter as to why you think you would be a good fit for arc-net.
        </p>
      </Fade>
    </VacancyTemplate>
  );
};

export default JuniorDesigner;
