import React from 'react';
import NewsTemplate from '../components/common/NewsTemplate';

export default function merryChristmas(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Merry Christmas"
      image={require('../img/blog/blog-christmas-featured-square@2x.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>It’s been a big year here at arc-net HQ.</p>
      <p>
        We’ve quadrupled the size of the Edinburgh team, added thousands of new unique products
        ranging from whisky to pigs to our platform and shared fascinating views into the supply of
        these brands with their customers.
      </p>
      <p>
        This week will also be the last in our current office as we’re moving to new digs on the
        other side of town which will be a huge upgrade for us and we can’t wait!
      </p>
      <p>
        Christmas always seems to be a retrospective period but this year I find I’m much more
        focused on the future for the arc-net team. We’re continuing to grow our support of the
        spirit drinks industry, particularly here in Scotland with new releases of Ailsa Bay whisky
        hitting the shelves with arc-net’s technology in place. The launch of our Distilled ID
        product is an indication of our commitment to the industry and we’ll be adding a suite of
        new features to that product in the new year.
      </p>
      <p>
        We’ve recently added another new member of staff to the infrastructure team here in
        Edinburgh and Kevin has taken no time at all getting his teeth into the work in front of
        him. The engineering team is firing on all cylinders and I couldn’t be happier with the
        progress they’re making. It gives me a great deal of hope for what we’re going to achieve
        over the coming months. There will be no resting on laurels around here, Q1 should see fresh
        changes to some of our core architecture, new integrations with accounting software, and the
        start of a complete rebuild of our user interfaces (both web and app). In between now and
        then though, a rest has been earned, the team are retreating to their various homes for the
        festive period and will return fresh in the new year (in our new office - for which I’ll
        have a new post for!).
      </p>
      <p>Merry Christmas from our family to yours and all the best for 2019!</p>
      <p>
        <i>Drew Lyall, General Manager</i>
      </p>
    </NewsTemplate>
  );
}
