import React from 'react';
import NewsTemplate from '../components/common/NewsTemplate';

export default function funding(props) {
  const { locations } = props;
  return (
    <NewsTemplate
      heading="Arc-net Secure £2m in Funding"
      image={require('../img/blog/funding.jpg')}
      next={locations.next}
      previous={locations.previous}
    >
      <p>
        Arc-net are delighted to announce that the company has secured £2m in investment in our
        latest round of funding.
      </p>
      <p>
        As a result of this investment we are also pleased to welcome Dr Richard Steeves to the team
        as Chairman. Dr Steeves is not only a former EY UK Entrepreneur of the Year, but a captain
        of industry. He arrives to arc-net with a track record in the growth and development of
        businesses around the globe.
      </p>
      <p>
        After receiving his PHD in Biochemistry, Dr Steeves founded Synergy Health in 1991 and has
        spearheaded it’s growth into a FTSE 250 global success. Discussing his decision to invest in
        arc-net, Dr Richard Steeves had this to say:
      </p>
      <p>
        “I am very excited to be supporting arc-net and their objective to revolutionise food supply
        chain security using their novel technology. I also find the social aspect of arc-net’s
        service compelling, working with the food industry to improve transparency and
        authenticity.”
      </p>
      <p>
        We are delighted that such a respected visionary like Dr Steeves has joined the arc-net
        family. Richard’s arrival is testament to both the significant steps forward the company has
        taken over the last year, as well as the social importance of the work we are doing.
      </p>
      <p>
        His investment, as well as his considerable experience and knowledge, will provide a solid
        foundation as we aim to take even larger steps in our journey to deliver a more traceable,
        verifiable and trusted world.
      </p>
      <p>Check out what the press have been saying about our big news below!</p>
      <p>
        <a
          href="http://www.irishtimes.com/business/technology/belfast-based-food-fraud-tech-firm-secures-2m-investment-1.2961573"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          Irish Times
          {' '}
        </a>
      </p>
      <p>
        <a
          href="http://www.thegrocer.co.uk/finance/fundraising/food-chain-traceability-specialist-arc-net-secures-2m-investment/548127.article?redirCanon=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          The Grocer
          {' '}
        </a>
      </p>
      <p>
        <a
          href="http://www.scotsman.com/business/companies/tech/food-chain-start-up-sinks-teeth-into-2m-funding-deal-1-4357240"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          The Scotsman
          {' '}
        </a>
      </p>
      <p>
        <a
          href="https://agfundernews.com/arc-net-raises-2m-food-supply-chain-traceability-using-blockchain-tech.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          AgFunder News
          {' '}
        </a>
      </p>
      <p>
        <a
          href="http://www.foodmanufacture.co.uk/Business-News/Food-safety-company-receives-2M-investment"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          Food Manufacture
          {' '}
        </a>
      </p>
      Irish Times The Grocer The Scotsman AgFunder News Food Manufacture
    </NewsTemplate>
  );
}
